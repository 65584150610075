import { makeStyles } from "@mui/styles";
import { Theme } from "@material-ui/core";
export const ticketDetailsStyles = makeStyles((theme: Theme) => ({
  // Table css
  createTicketFields: {
    width: "100%",
    gap: "16px",
    display: "flex",
    flexDirection: "column",
    paddingTop: "36px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      border: "1px solid #D6D1FA"
    }
  },
  row: {
    cursor: "pointer"
  },
  hover: {
    "&:hover": {
      backgroundColor: "#293368 !important"
    }
  },
  iconColor: {
    "&.MuiIconButton-root": {
      color: "#1976d2"
    }
  },
  iconFilterButtonBg: {
    "&.MuiIconButton-root": {
      color: "#1976d2",
      backgroundColor: "#E5E7F1"
    }
  },
  iconFilterButtonNonBg: {
    "&.MuiIconButton-root": {
      color: "#1976d2",
      backgroundColor: "transparent"
    }
  },
  tableRow1: {
    "&.MuiTableRow-root": {
      backgroundColor: "#242C59",

      "&:hover": {
        backgroundColor: "#242C5990"
      }
    }
  },
  tableRow2: {
    "&.MuiTableRow-root": {
      backgroundColor: "#2D376D",

      "&:hover": {
        backgroundColor: "#2D376D70"
      }
    }
  },
  tableBodyTypography: {
    "&.MuiTypography-root": {
      fontWeight: 500,
      fontSize: "14px",
      color: "#FFF"
    }
  },

  High: {
    "&.MuiTypography-root": {
      color: "#E06060"
    }
  },

  Low: {
    "&.MuiTypography-root": {
      color: "#FFF"
    }
  },

  Medium: {
    "&.MuiTypography-root": {
      color: "#FF0"
    }
  },

  waterStatus: {
    minWidth: "98px",
    padding: "1.5px 10px",
    fontSize: "14px",
    fontWeight: 500,
    maxWidth: "fit-content",
    borderRadius: "20px",
    textAlign: "center"
  },

  Closed: {
    background: "#1DAC56"
  },
  TBD: {
    background: "#FF8A65"
  },
  confirmed: {
    background: "transparent",
    border: "0.5px solid #FFF"
  },
  InProgress: {
    background: "#FF0",
    color: "#232A58"
  },
  Open: {
    background: "#F00"
  },

  tableHeadTypography: {
    "&.MuiTableCell-root": {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "31px",
      color: "#E6E1E5",
      //   display: "inline-flex",
      //   justifyContent: "space-between",
      //   width: "100%",
      position: "relative",
      "& .MuiIconButton-root": {
        position: "absolute",
        right: " 20px",
        top: "20px",
        color: "#fff",
        textTranform: "capitalize"
      }
    }
  },
  tableBodyCell: {
    "&.MuiTableCell-root": {
      textAlign: "center"
    }
  },
  // Header css
  criticalControlSec: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      border: "1px solid #8476ef",
      background: "#293368",
      padding: "12px !important",
      "& .MuiAutocomplete-input": {
        padding: "0 !important"
      }
    },
    "& .MuiInputBase-input": {
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "14px",
      "&::placeholder": {
        color: "#FFF"
      }
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "12px"
    },
    "& .css-19kzrtu": {
      padding: 0
    }
  },
  fliterMenus: {
    display: "flex",
    gap: "12px",
    justifyContent: "flex-end",
    "& .MuiButtonBase-root": {
      borderRadius: "6px",
      border: "0.895px solid #8476EF",
      background: "#293368",
      display: "flex",
      height: "46px",
      padding: "10px 20px",
      alignItems: "center",
      gap: "20px",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      maxWidth: "max-content",
      width: "100%",
      "& .MuiButton-iconSizeMedium": {
        marginRight: 0
      }
    },
    "& .MuiTabs-scroller": {
      overflow: "visible !important",
      "& .MuiButtonBase-root": {
        margin: 0,
        borderColor: "#ABA7AD",
        background: "#232A58",
        "&:first-child": {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0
        },
        "&:last-child": {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0
        }
      },
      "& .Mui-selected": {
        borderColor: "#8476EF",
        background:
          "linear-gradient(0deg, rgba(132, 118, 239, 0.30) 0%, rgba(132, 118, 239, 0.30) 100%), #232A58"
      }
    }
  },
  createBtn: {
    "& .MuiButtonBase-root": {
      borderRadius: "10px",
      background: "#8476EF",
      padding: "9px 41px",
      width: "auto"
    }
  },
  datePickerBox: {
    "&.rs-picker-default ": {
      "& .rs-picker-toggle.rs-btn": {
        background: "#293368 !important",
        borderRadius: "10px",
        border: "1px solid #8476EF",
        padding: "14px 32px",
        fontWeight: 500
      },
      "& .rs-picker-toggle-caret": {
        display: "none"
      }
    },
    "& .rs-picker-toggle-textbox": {
      background: "#293368",
      color: "#fff",
      fontSize: "13px"
    },
    "& .rs-stack": {
      "& .rs-stack-item": {
        "& .rs-picker-toggle-clean": {
          top: "14px !important"
          // background: "blue",
        }
      },
      "& .rs-picker-toggle-textbox": {
        padding: "14px 32px",
        color: "#fff"
      }
    },
    "& .rs-picker-toggle-value, .rs-picker-toggle-placeholder": {
      color: "#fff !important"
    }
  },
  multiDropdown: {
    "& .select__single-value": {
      color: "#fff !important",
      padding: "13px"
    },
    "& .select__input": {
      color: "white !important"
    },
    width: "100%",
    "& .select__control": {
      background: "#293368",
      padding: "11.5px 16px",
      "& .select__multi-value": {
        background: "#8476EF",
        padding: "6px 10px",
        "& .select__multi-value__label": {
          color: "#FFF",
          fontSize: "14px"
        },
        "& .select__multi-value__remove": {
          "&:hover": {
            backgroundColor: "transparent",
            color: "#FFF"
          }
        }
      },
      "& .select__indicator-separator": {
        display: "none"
      }
    },
    "& .select__menu ": {
      "& .select__menu-list": {
        padding: 0,
        "& .select__option ": {
          background: "#293368"
        }
      }
    },

    "& .MuiFormLabel-root ": {
      top: "-25px",
      background: "#293368",
      padding: "2px 10px"
      // "&:after": {
      //   content: "",
      //   background: "red",
      //   width: "100%",
      //   height: "10px",
      //   display: "block",
      // },
    }
  },

  bookmarkDialog: {
    "& .MuiDialogTitle-root": {
      fontSize: "20px",
      fontWeight: 500,
      marginBottom: "0px",
      padding: "0px"
    },
    "& .MuiTypography-body1": {
      color: "#FFF",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "14px"
    },

    "& .contentWrapper": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "15px !important"
    },

    "& .MuiAutocomplete-clearIndicator": {
      background: "transparent",
      "& .MuiSvgIcon-root": {
        color: "#fff"
      }
    },
    "& .btnFoot": {
      display: "flex",
      gap: "19px",
      width: "100%",
      justifyContent: "center",
      "& .MuiButtonBase-root": {
        borderRadius: "6px",
        border: "0.895px solid #8476EF",
        background: "#293368",
        display: "flex",
        height: "46px",
        padding: "9px 41px",
        alignItems: "center",
        gap: "20px",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        maxWidth: "max-content",
        width: "100%",
        "& .MuiButton-iconSizeMedium": {
          marginRight: 0
        }
      },
      "& .saveBtn": {
        background: "#8476EF"
      }
    }
  },
  tableGrid: {
    marginTop: "24px",
    marginBottom: "24px"
  },
  autoCompletetSelect: {
    display: "contents",
    "& .MuiInputBase-root": {
      border: "none"
    },
    "& .MuiAutocomplete-input": {
      color: "#fff",
      "&::placeholder": {
        color: "#fff"
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #ABA7AD",
      borderRadius: "5px",
      background: "#293368"
    }
  },
  backButtonAndAutoComplete: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row"
  },
  tagsConatiner: {
    marginTop: "10px",
    marginLeft: "10px",
    display: "flex",
    flexDirection: "column",
    color: "black"
  },
  tabsControl: {
    "& .MuiTabs-flexContainer": {
      width: "fit-content",
      maxWidth: "100%",
      "& .MuiButtonBase-root": {
        borderRadius: "0",
        border: "1px solid #ABA7AD",
        margin: "0",

        "&:first-child": {
          borderTopLeftRadius: "59px",
          borderBottomLeftRadius: "59px",
          borderRight: "0"
        },
        "&:last-child": {
          borderTopRightRadius: "59px",
          borderBottomRightRadius: "59px",
          borderLeft: "0"
        },
        "&.Mui-selected": {
          background: "rgba(132, 118, 239, 0.30)",
          borderColor: "#8476EF"
        }
      }
    },
    "& .MuiTabs-indicator": {
      background: "transparent"
    }
  },
  searchBarHead: {
    gap: "16px"
  },
  autoDropdown: {
    "& .MuiInputBase-root": {
      display: "flex"
    },
    "& .MuiInputBase-input": {
      width: "100% !important"
    }
  },

  ticketStatusClosed: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      border: "none"
    },

    "& .MuiInputBase-root": {
      border: "1px solid #1DAC56",
      background: "#1DAC56"
    },
    "& .MuiIconButton-root": {
      color: "#fff"
    },
    "& .MuiAutocomplete-input": {
      color: "#FFF",
      textAlign: "center"
    }
  },

  ticketStausTBD: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      color: "white"
    },
    "& .MuiInputBase-root": {
      border: "1px solid #FF8A65",
      background: "#FF8A65"
    },
    "& .MuiIconButton-root": {
      color: "#fff"
    },
    "& .MuiAutocomplete-input": {
      color: "white",
      textAlign: "center"
    }
  },

  ticketStausOpen: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      color: "white"
    },
    "& .MuiInputBase-root": {
      border: "1px solid #F00",
      background: "#F00"
    },
    "& .MuiIconButton-root": {
      color: "#fff"
    },
    "& .MuiAutocomplete-input": {
      color: "white",
      textAlign: "center"
    }
  },

  ticketStausInProgress: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      color: "white"
    },
    "& .MuiInputBase-root": {
      border: "1px solid #FF0",
      background: "#FF0"
    },
    "& .MuiIconButton-root": {
      color: "#fff"
    },
    "& .MuiAutocomplete-input": {
      color: "#000",
      textAlign: "center"
    }
  },

  ticketStausConfirmed: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      color: "white"
    },
    "& .MuiInputBase-root": {
      border: "1px solid #FFF",
      background: "transparent"
    },
    "& .MuiIconButton-root": {
      color: "#fff"
    },
    "& .MuiAutocomplete-input": {
      color: "#000",
      textAlign: "center"
    }
  },

  ticketStausPlannerConfirmed: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      color: "white"
    },
    "& .MuiInputBase-root": {
      border: "1px solid #FF0",
      background: "#FF0"
    },
    "& .MuiIconButton-root": {
      color: "#fff"
    },
    "& .MuiAutocomplete-input": {
      color: "#000",
      textAlign: "center"
    }
  },

  MoreVertiIcon: {
    "& .MuiIconButton-root": {
      border: "1px solid #8476EF",
      color: "#fff",
      height: "46px"
    }
  },
  priorityBtn: {
    "&.MuiButton-root": {
      padding: "9px 41px",
      borderRadius: "10px",
      width: "auto"
    }
  },
  HighPriority: {
    "&.MuiButton-root": {
      border: " 1px solid #E06060",
      background: "#E06060",
      "&:hover": {
        border: " 1px solid #E06060",
        background: "#E06060"
      }
    }
  },
  LowPriority: {
    "&.MuiButton-root": {
      border: " 1px solid #FFF",
      background: "#FFF",
      color: "#232A58",
      "&:hover": {
        border: " 1px solid #FFF",
        background: "#FFF",
        color: "#232A58"
      }
    }
  },
  MediumPriority: {
    "&.MuiButton-root": {
      border: " 1px solid #FF0",
      background: "#FF0",
      color: "#232A58",
      "&:hover": {
        border: " 1px solid #FF0",
        background: "#FF0",
        color: "#232A58"
      }
    }
  },
  ticketType: {
    minWidth: "215px",
    padding: "9px 41px",
    borderRadius: "10px",
    color: "#fff"
  },
  openTicket: {
    "&.MuiButton-root": {
      borderRadius: "10px",
      minWidth: "215px",
      padding: "9px 41px",
      border: "1px solid #F00",
      background: " rgba(255, 0, 0, 0.12)"
    }
  },
  menuOption: {
    minWidth: "10px",
    width: "auto"
  },

  ticketDetail: {
    display: "flex",
    justifyContent: "space-between",
    gap: "24px"
  },
  ticketDetailBox: {
    "& .MuiTypography-body1": {
      fontSize: "16px",
      fontWeight: 500,
      color: " #FFF",
      marginBottom: "6px"
    },
    "& .MuiTypography-body2": {
      color: "#D9D9D9",
      fontSize: "14px",
      fontWeight: 500
    }
  },
  detailCard: {
    padding: "16px 24px",
    borderRadius: "10px",
    background: "#293368",
    marginBottom: "24px"
  },
  detailCardHeader: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    marginBottom: "20px",
    "& .MuiButtonBase-root": {
      padding: "1.5px 10px",
      width: "auto",
      fontSize: "14px",
      fontWeight: 400
    }
  },
  ticketPendingApproval:{
    "&.MuiButtonBase-root": {
      color: "#FF0",
      borderRadius: "4px",
      border: "1px solid #FF0",
      background: "#232A58"
    }
  },
  ticketApproved:{
    "&.MuiButtonBase-root": {
      color: "#1DAC56",
      borderRadius: "4px",
      border: "1px solid #1DAC56",
      background: "#232A58"
    }
  },
  ticketReject: {
    "&.MuiButtonBase-root": {
      color: "#F00",
      borderRadius: "4px",
      border: "1px solid #F00",
      background: "#232A58"
    }
  },
  openTicketOpen: {
    "&.MuiButtonBase-root": {
      color: "#F00",
      borderRadius: "4px",
      border: "1px solid #F00",
      background: "#232A58"
    }
  },
  openTicketClosed: {
    "&.MuiButtonBase-root": {
      color: "#1DAC56",
      borderRadius: "4px",
      border: "1px solid #1DAC56",
      background: "#232A58"
    }
  },
  openTicketInProgress: {
    "&.MuiButtonBase-root": {
      color: "#FF0",
      borderRadius: "4px",
      border: "1px solid #FF0",
      background: "#232A58"
    }
  },
  openTicketTBD: {
    "&.MuiButtonBase-root": {
      color: "#FF8A65",
      borderRadius: "4px",
      border: "1px solid #FF8A65",
      background: "#232A58"
    }
  },
  openTicketPlannerConfirmed: {
    "&.MuiButtonBase-root": {
      color: "#FF0",
      borderRadius: "4px",
      border: "1px solid #FF0",
      background: "#232A58"
    }
  },
  detailCardTitle: {
    "&.MuiTypography-body1": {
      fontSize: "20px"
    }
  },
  detailCardBox: {
    display: "flex",
    justifyContent: "space-between",
    gap: "24px"
  },
  detailCardContent: {
    "& .MuiTypography-body1": {
      fontSize: "14px",
      fontWeight: 400,
      marginBottom: "16px"
    },
    "& .MuiTypography-body2": {
      fontSize: "18px",
      fontWeight: 600
    }
  },

  Attachments: {
    "&.MuiButtonBase-root": {
      borderRadius: "5px",
      border: "1px solid #8476EF",
      background: "none",
      padding: "10px 16px",
      fontSize: "13px",
      fontWeight: 500
    }
  },
  addNewBtn: {
    "&.MuiButtonBase-root": {
      fontSize: "13px",
      fontWeight: 500,
      margin: "4px"
    }
  },
  commentHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    "& .MuiTypography-body1": {
      fontSize: "20px",
      "@media only screen and (max-width:1600px) ": {
        fontSize: "16px"
      },
      "@media only screen and (max-width:1280px) ": {
        fontSize: "14px"
      }
    },

    "& .MuiButtonBase-root": {
      "@media only screen and (max-width:1600px) ": {
        fontSize: "12px",
        padding: "10px 14px"
      },
      "@media only screen and (max-width:1440px) ": {
        fontSize: "10px",
        padding: "10px 12px"
      }
    }
  },

  CommentsWrapper: {
    height: "50vh",
    overflowY: "auto",
    display: "flex",
    gap: "20px",
    flexDirection: "column"
  },
  commentsBox: {
    "&.MuiCard-root": {
      position: "relative",
      padding: "20px 15px",
      borderRadius: "8px",
      background: "#414485",
      display: "flex",
      flexDirection: "column",
      gap: "15px",
      overflow: "initial"
    }
  },
  commentsEdit: {
    position: "absolute",
    right: "6px",
    top: "6px",
    "& .MuiButtonBase-root": {
      padding: "5px",
      background: "transparent"
    }
  },
  userDetail: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
    "& img": {
      borderRadius: "36px",
      border: "1px solid #8476EF",
      width: "36px",
      height: "36px"
    },
    "& .MuiTypography-body1": {
      fontSize: "16px",
      fontWeight: 600,
      "& span": {
        color: "#ABA7AD",
        fontSize: "14px",
        marginTop: "4px",
        display: "block"
      }
    }
  },
  commentsTitle: {
    fontSize: "16px"
  },
  commentsdetail: {
    fontSize: "14px",
    fontWeight: 500,
    opacity: "0.6"
  },
  commentsFilesAttachment: {
    "& .MuiButtonBase-root": {
      borderRadius: "5px",
      background: "#293368",
      padding: "5px 10px",
      fontSize: "12px",
      fontWeight: 400,
      border: "none !important",
      "&:hover": {
        background: "#293368"
      },
      "& .MuiButton-endIcon": {
        marginRight: "0px",
        "& svg": {
          width: "16px",
          height: "16px"
        }
      }
    }
  },
  attachmentContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  attachmentDesc: {
    "& .MuiTypography-body1": {
      fontSize: "16px",
      fontWeight: 600,
      marginBottom: "4px",
      textAlign: "left"
    },
    "& .MuiTypography-body2": {
      fontSize: "14px",
      fontWeight: 400,
      "& span": {
        fontWeight: 500,
        display: "block",
        marginTop: "4px"
      }
    }
  },
  attachmentBox: {
    borderRadius: "5px",
    background: "#293368",
    padding: "30px 15px",
    position: "relative",
    marginBottom: "24px",
    width: "118px",
    height: "118px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  hMenuIcon: {
    position: "absolute",
    top: "3px",
    right: "3px",
    cursor: "pointer"
  },
  imgThubnail: {
    maxWidth: "94px",
    width: "100%",
    cursor: "pointer"
  },
  imageViewer: {
    background: "#1A1D33",
    minHeight: "460px"
  },
  imageViewerDialog: {
    "& .MuiDialogTitle-root": {
      fontSize: "20px",
      fontWeight: 500,
      marginBottom: "0px",
      padding: "0px"
    },

    "& .contentWrapper": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "10px",
      width: "100%"
    },
    "&.MuiDialog-root ": {
      "& .MuiDialog-paper": {
        background: "#232A58"
      }
    },
    "& .MuiAutocomplete-endAdornment": {
      right: "0px !important"
    },
    "& .MuiAutocomplete-root": {
      marginTop: "50px"
    },
    "& .MuiAutocomplete-clearIndicator": {
      background: "transparent",
      "& .MuiSvgIcon-root": {
        color: "#fff"
      }
    }
  },

  statusDatePiker: {
    width: "100%",
    margin: "8px 0px",
    "& fieldset": {
      borderColor: "#fff"
    },
    "& > div": {
      width: "100%",
      "& .MuiFormControl-root": {
        width: "100%"
      }
    }
  },
  statusAttechment: {
    "& label": {
      fontSize: "14px",
      color: "#fff",
      opacity: "0.5"
    }
  },
  statusCommentBox: {
    "& fieldset": {
      borderColor: "#fff"
    }
  },
  statusAttechmentButton: {
    "&.MuiButton-root": {
      backgroundColor: "#293368",
      border: "1px solid #8476EF",
      justifyContent: "space-between",
      overflow: "hidden",
      padding: "0px 0px 0px 15px",
      minWidth: "auto",
      "& .MuiButton-endIcon": {
        padding: "6px",
        background: "rgba(18, 69, 168, 0.20)",
        margin: "0px"
      }
    }
  },
  nav: {
    "&.MuiList-root.MuiList-root": {
      background: "#2F3B79",
      padding: "10px 5px"
    }
  },
  listButton: {
    "&.MuiListItemButton-root": {
      marginBottom: "6px"
    }
  },
  tickeDialog: {
    "& .MuiTypography-h6": {
      fontSize: "20px",
      fontWeight: 400,
      textAlign: "center",
      padding: "0",
      "& .MuiTypography-body1": {
        fontSize: "12px",
        fontWeight: 300
      }
    },
    "& .MuiList-root": {
      padding: "0",
      "& .MuiListItem-root": {
        padding: "0 0 0 4px",
        fontSize: "12px",
        fontWeight: 300
      }
    },
    "& .btnFoot": {
      display: "flex",
      gap: "12px",
      width: "100%",
      marginTop: "19px",
      "& .MuiButtonBase-root": {
        borderRadius: "6px",
        border: "0.895px solid #8476EF",
        background: "#293368",
        display: "flex",
        height: "46px",
        padding: "10px 20px",
        alignItems: "center",
        gap: "20px",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        width: "100%",
        "& .MuiButton-iconSizeMedium": {
          marginRight: 0
        }
      },
      "& .saveBtn": {
        background: "#8476EF"
      },
      "& .deleteBtn": {
        background: "#E06060",
        border: "none",
        fontWeight: 500
      },
      "& .doneBtn": {
        "&.MuiButtonBase-root": {
          maxWidth: "146px",
          margin: "0 auto",
          background: "#8476EF"
        }
      }
    }
  },

  imagePreviewWrapper: {
    width: "1000px",
    padding: "24px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    background: "#232A58"
  },
  imagePreviewBody: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  dialogImage: {
    height: "100%",
    width: "100%"
  },
  attachmentsDialogWrapper: {
    width: "500px",
    padding: "28px 20px",
    // display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    overflowX: "hidden",
    height: "600px"
  },
  logilityDialogWrapper: {
    width: "598px",
    padding: "24px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    background: "#232A58"
  },
  logilityDialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  attachmentCloseIcon: {
    marginLeft: "10px",
    marginTop: "5px"
  }
}));
