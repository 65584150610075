import {
  LOAD_SEEDS,
  LOAD_SEEDS_SUCCESS,
  LOAD_SEEDS_ERROR,
  UPDATE_ASEPTIC_RECIPE_SEED_DATA,
  UPDATE_ASEPTIC_RECIPE_SEED_DATA_SUCCESS,
  UPDATE_ASEPTIC_RECIPE_SEED_DATA_ERROR,
  UPDATE_SEED,
  UPDATE_SEED_SUCCESS,
} from "../Types/seeds.constants";

const INITIAL_STATE = {
  loading: false,
  seeds: [],
  error: null,
};

export const seedsListReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_SEEDS:
      return { ...state, loading: true, seeds: [] };
    case LOAD_SEEDS_SUCCESS:
      return {
        ...state,
        loading: false,
        seeds: action.payload,
      };
    case LOAD_SEEDS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_ASEPTIC_RECIPE_SEED_DATA:
      return { ...state, loading: true };
    case UPDATE_ASEPTIC_RECIPE_SEED_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        seeds: Array.isArray(action.payload)
          ? [
              ...state.seeds.filter((ele: any) => {
                return ele._id !== action.payload._id;
              }),
              ...action.payload,
            ]
          : [
              ...state.seeds.filter((ele: any) => {
                return ele._id !== action.payload._id;
              }),
              action.payload,
            ],
      };
    case UPDATE_ASEPTIC_RECIPE_SEED_DATA_ERROR:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_SEED:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SEED_SUCCESS:
      return {
        ...state,
        loading: false,
        seeds: [
          ...state.seeds.filter((ele: any) => {
            return ele._id !== JSON.parse(action.payload)._id;
          }),
          JSON.parse(action.payload),
        ],
      };
    default:
      return state;
  }
};
