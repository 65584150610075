import { memo, useEffect } from "react";
import { isEqual, omit } from "lodash";
import SensorStatus from "./SensorStatus";

const DisconnectedSensorsPage = (props: any) => {
  //filtering tags based on asset tag from the schema
  let uniqueTags = props.connectionStatusTags.filter((tag: any) => {
    return (
      tag.includes(`${props.tag}_`) &&
      (props.sensor?.sensor_code !== undefined
        ? tag.includes(`_${props.sensor?.sensor_code}_`)
        : true)
    );
  });
  if (
    uniqueTags.filter(
      (item: any, index: any) => uniqueTags.indexOf(item) === index
    ).length > 1
  ) {
    uniqueTags = uniqueTags.filter((tag: any) => {
      return tag.includes(`${props.asset?.asset_name.replace(/ /g, "")}_`);
    });
  }

  const getElectricalNumber = (electricalNumber: any) => {
    if (electricalNumber === undefined || electricalNumber === "") {
      return "NA";
    } else {
      return electricalNumber;
    }
  };

  //function to add new disconnected sensor and update disconnected sensors state
  const updateDisconnectedSensorsList = () => {
    const currentData: any = [...props.disConnectedSensorsList];
    const assetGroup = props.currentPlantAssets?.find(
      (asset: any) => asset?._id === props?.asset?.parent_asset
    );
    const assetArea = props.currentPlantAssets?.find(
      (asset: any) => asset?._id === assetGroup?.parent_asset
    );
    const newSensor = {
      asset_area: assetArea?.asset_alias || assetArea?.asset_name || "NA",
      asset_group: assetGroup?.asset_alias || assetGroup?.asset_name || "NA",
      asset_name: props?.asset?.asset_alias || props?.asset?.asset_name || "NA",
      sensors: props.sensor === undefined ? "D" : props.sensor?.sensor_code,
      sensor_code: props.sensorNumber || "NA",
      electrical_number:
        getElectricalNumber(props?.asset?.electrical_number) || "NA",
    };
    if (
      !currentData.some(
        (current: any) =>
          isEqual(
            omit(current, ["sensors", "sensor_code"]),
            omit(newSensor, ["sensors", "sensor_code"])
          ) && current.sensors.split(", ").includes(newSensor.sensors)
      )
    ) {
      const existingSensorIndex = currentData.findIndex((current: any) =>
        isEqual(
          omit(current, ["sensors", "sensor_code"]),
          omit(newSensor, ["sensors", "sensor_code"])
        )
      );
      if (existingSensorIndex !== -1) {
        currentData[existingSensorIndex].sensors += `, ${newSensor.sensors}`;
        currentData[existingSensorIndex].sensor_code +=
          `, ${newSensor.sensor_code}`;
        props.setDisConnectedSensorsList([...currentData]);
      } else {
        props.setDisConnectedSensorsList((prevState: any) =>
          [...prevState, newSensor].sort((a: any, b: any) =>
            a.asset_group.toLowerCase() > b.asset_group.toLowerCase() ? 1 : -1
          )
        );
      }
    }
  };

  //effect for updating disconnected sensors list of unique tags are empty
  useEffect(() => {
    if (uniqueTags.length === 0 && props.connectionStatusTags.length !== 0) {
      updateDisconnectedSensorsList();
    }
  }, [props.connectionStatusTags]);

  return (
    <SensorStatus
      uniqueTags={uniqueTags}
      sensor={props.sensor}
      tag={props.tag}
      correspondingCubes={props.correspondingCubes}
      updateDisconnectedSensorsList={updateDisconnectedSensorsList}
    />
  );
};

export default memo(DisconnectedSensorsPage);
