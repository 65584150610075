import moment from 'moment'
import findAsset from '../../../../Utils/UtilFunctions/findAsset'
import {
	getFormatedDate,
	getTimeZoneTimeStamp
} from '../../../../Utils/UtilFunctions/timeZoneFormatter'
import {
	BLOCKED_CAVITY,
	CLOSURE_MOLD_QUARTERLY_PM,
	CYCLE_TIME,
	HOT_RUNNER_REFURBISHMENT
} from '../../../master/UsecaseDashboard/InjectionAnalytics/Helpers'

let validationRequiredForOOS = false
let validationRequiredForByPass = false
let oosStatus = false

export const isValueOutOfBounds = (
	disable = false,
	oosAlerts: any,
	bypassAlerts: any
) => {
	if (oosAlerts.length > 0) {
		validationRequiredForOOS = true
	}
	if (bypassAlerts.length > 0) {
		validationRequiredForByPass = true
	}

	const upperLimitForOOS = oosAlerts[0]?.message_data.HighLimit
	const lowerLimitForOOS = oosAlerts[0]?.message_data.LowLimit
	const currentValueForOOS = oosAlerts[0]?.message_data.TagValueDouble

	if (!validationRequiredForOOS && !validationRequiredForByPass) {
		return disable
	}

	if (
		validationRequiredForOOS &&
		(parseFloat(currentValueForOOS) <= parseFloat(lowerLimitForOOS) ||
			parseFloat(currentValueForOOS) >= parseFloat(upperLimitForOOS))
	) {
		oosStatus = true
	}

	// 90843 - removing the check for comparing bypass alarm to close a WQMS ticket
	if (oosStatus) {
		return !disable
	} else {
		return disable
	}
}

export const findSensor: any = (tagname: string) => {
	const sensorLastString = tagname.substring(tagname.length - 1)
	const sensorLastPrevString = tagname.substring(tagname.length - 2)
	if (sensorLastPrevString === 'GB') {
		return 'Gearbox'
	} else if (sensorLastPrevString === 'ND') {
		return 'Non-Drive'
	} else if (sensorLastString === 'D') {
		return 'Drive'
	} else if (sensorLastString === 'P') {
		return 'Pump'
	} else if (sensorLastString === 'B') {
		return 'Bearing'
	} else {
		return 'NA'
	}
}

export const getFileURL = (tempObj: any) => {
	function getFileName(url: string) {
		const startPt = url.lastIndexOf('/') + 1
		return decodeURIComponent(url.slice(startPt, url.length))
	}
	if (tempObj !== undefined && tempObj.entityMap !== undefined) {
		const temp2: any = Object.values(tempObj.entityMap)
			.filter((ele: any) => {
				return ele.type === 'LINK'
			})
			.map((file: any) => {
				return {
					src: file.data.url,
					type: file.mutability === 'IMMUTABLE' ? 'file' : 'link',
					name: getFileName(file.data.url)
				}
			})
		return temp2
	}
}

export const getImageURL = (tempObj: any) => {
	if (tempObj !== undefined && tempObj.entityMap !== undefined) {
		const temp2: any = Object.values(tempObj.entityMap)
			.filter((ele: any) => {
				return ele.type === 'IMAGE'
			})
			.map((image: any) => {
				return { src: image.data.src, type: 'img' }
			})
		return temp2
	}
}

export const checkAlertStatus = (data: any) => {
	return (
		data.ALERTFLAG.toLowerCase() === 'Okay'.toLowerCase() &&
		data.TAGVALUE >= data.LOWERLIMIT &&
		data.TAGVALUE <= data.UPPERLIMIT
	)
}

export const checkAlertStatusCCP = (data: any) => {
	return (
		data.ALERTFLAG.toLowerCase() === 'Okay'.toLowerCase() &&
		data.TAGVALUE <= data.UPPERLIMIT
	)
}

export const renderAlertHistoryTableForPdfInjection = (
	currentMessage: any,
	alert: any,
	currentPlant: any
) => {
	switch (currentMessage?.message_data?.AlertSubType) {
		case HOT_RUNNER_REFURBISHMENT:
			return {
				Timestamp: getFormatedDate(
					currentPlant.plant_timezone,
					getTimeZoneTimeStamp(currentPlant.plant_timezone, alert.timestamp)
				),
				// 'Alert Type': currentMessage?.message_data?.AlertSubType,
				'Current Health': currentMessage?.message_data?.usecase_payload?.status || '-',
				"Days Until Projection":currentMessage?.message_data?.usecase_payload?.daysUntilProjection || '-' ,
				"Projected Date": moment().add(currentMessage.message_data?.usecase_payload?.daysUntilProjection, "days").format("MM/DD/YYYY"),
				"Last PM Completion Date": currentMessage.message_data?.usecase_payload?.lastPMCompletionDate || '-'
			}
			case BLOCKED_CAVITY:
				return  {
					Timestamp: getFormatedDate(
						currentPlant.plant_timezone,
						getTimeZoneTimeStamp(currentPlant.plant_timezone, alert.timestamp)
					),
					// 'Alert Type': currentMessage?.message_data?.AlertSubType,
					'Active Cavities': currentMessage?.message_data?.usecase_payload?.activeCavities || "NA",
					'Blocked Cavities': (currentMessage?.message_data?.usecase_payload?.blockedCavityCount || currentMessage?.message_data?.usecase_payload?.blockedCavityCount === 0 ) ? currentMessage?.message_data?.usecase_payload?.blockedCavityCount  : "NA",
					'Total Cavities': currentMessage?.message_data?.usecase_payload?.totalCavities || "NA",
					'% Blocked Cavities': currentMessage?.message_data?.usecase_payload?.totalCavities && (currentMessage?.message_data?.usecase_payload?.blockedCavityCount || currentMessage?.message_data?.usecase_payload?.blockedCavityCount === 0)
						? `${
								(currentMessage?.message_data?.usecase_payload?.totalCavities *
									currentMessage?.message_data?.usecase_payload?.blockedCavityCount) /
								100
							}`
						: 'NA',
				'Days With Cavities Blocked':
					blockedCavityDatesDifference(
						currentMessage?.message_data?.usecase_payload?.openedDate,
						currentMessage?.message_data?.usecase_payload?.closedDate
					) || 'NA'
			}
		case CYCLE_TIME:
			return {
				Timestamp: getFormatedDate(
					currentPlant.plant_timezone,
					getTimeZoneTimeStamp(currentPlant.plant_timezone, alert.timestamp)
				),
				// 'Alert Type': currentMessage?.message_data?.AlertSubType,
				'Average Cycle Time':
					currentMessage?.message_data?.usecase_payload?.avgCycleTimeSeconds?.toFixed(
						2
					) || 'NA',
				'Target Cycle Time':
					currentMessage?.message_data?.usecase_payload?.targetCycleTime?.toFixed(
						2
					) || 'NA',
				UL:
					currentMessage?.message_data?.usecase_payload?.upperLimit?.toFixed(
						2
					) || 'NA',
				LL:
					currentMessage?.message_data?.usecase_payload?.lowerLimit?.toFixed(
						2
					) || 'NA',
				'Days OOS':
					blockedCavityDatesDifference(
						currentMessage?.message_data?.usecase_payload?.oos_date,
						currentMessage?.message_data?.usecase_payload?.oos_resolved_date
					) || 'NA'
			}
		default:
			return {
				Timestamp: getFormatedDate(
					currentPlant.plant_timezone,
					getTimeZoneTimeStamp(currentPlant.plant_timezone, alert.timestamp)
				),
				'Alert Type': alert.alert_type,
				Tag: alert.tag,
				// "Operating Hours": alert.message_data?.OperatingHours || "NA",
				'Projected Date':
					alert.message_data?.ProjectedDate !== undefined &&
					alert.message_data?.ProjectedDate !== null
						? getFormatedDate(
								currentPlant.plant_timezone,
								getTimeZoneTimeStamp(
									currentPlant.plant_timezone,
									alert.message_data?.ProjectedDate
								)
							)
						: 'NA',
				'Projected Release Date':
					alert.message_data?.ProjectedReleaseDate !== undefined &&
					alert.message_data?.ProjectedReleaseDate !== null
						? getFormatedDate(
								currentPlant.plant_timezone,
								getTimeZoneTimeStamp(
									currentPlant.plant_timezone,
									alert.message_data?.ProjectedReleaseDate
								)
							)
						: 'NA'
			}
	}
}

export const getCommonAlertHistoryHistoryExportObjectForInjection = (
	alert: any,
	ticketsList: any,
	plantsList: any,
	currentTicket: any,
	parentAsset: any,
	grandParentAsset: any
) => {
	const data = {
		'Ticket ID': currentTicket?.ticketId_alias,
		Timestamp: getFormatedDate(
			plantsList.plants.find((plant: any) => {
				return plant._id === currentTicket.plant_id
			}).plant_timezone,
			getTimeZoneTimeStamp(
				plantsList.plants.find((plant: any) => {
					return plant._id === currentTicket.plant_id
				}).plant_timezone,
				alert.timestamp
			)
		),
		'Alert Type': alert.alert_type || '-',
		'Alert SubType': alert.message_data?.AlertSubType || 'NA',
		'Machine Name': parentAsset.asset_alias || parentAsset.asset_name || '-',
	}

	return data
}

export const getCommonAlertHistoryHistoryExportObjectForLgv = (
	alert: any,	
	plantsList: any,	
	currentTicket: any,
	parentAsset: any
) => {
	const data = {
		'Ticket ID': currentTicket?.ticketId_alias,
		Timestamp: getFormatedDate(
			plantsList.plants.find((plant: any) => {
				return plant._id === currentTicket.plant_id
			}).plant_timezone,
			getTimeZoneTimeStamp(
				plantsList.plants.find((plant: any) => {
					return plant._id === currentTicket.plant_id
				}).plant_timezone,
				alert.timestamp
			)
		),
		'Alert Type': alert.alert_type || '-',
		'Machine Name': parentAsset.asset_alias || parentAsset.asset_name || '-',
	}
	return data
}

export const getModdedAlertHistoryExportForInjection = (
	alert: any,
	plantsList: any,
	currentTicket: any,
) => {
	let data = {}
	const commonObject = {
		'Ticket ID': currentTicket?.ticketId_alias,
		Timestamp: getFormatedDate(
			plantsList.plants.find((plant: any) => {
				return plant._id === currentTicket.plant_id
			}).plant_timezone,
			getTimeZoneTimeStamp(
				plantsList.plants.find((plant: any) => {
					return plant._id === currentTicket.plant_id
				}).plant_timezone,
				alert.timestamp
			)
		),
		'Alert Type': alert.alert_type || '-',
		'Alert SubType': alert.message_data?.AlertSubType || 'NA',
		'Machine Name': alert?.message_data?.usecase_payload?.machineDesignation || '-'
	}
	switch (alert.message_data?.AlertSubType) {
		case CYCLE_TIME:
			data = {
				...commonObject,
				'Average Cycle Time': alert?.message_data?.usecase_payload?.avgCycleTimeSeconds?.toFixed(2) ?? 'NA',
				'Target Cycle Time': alert?.message_data?.usecase_payload?.targetCycleTime?.toFixed(2) ?? 'NA',
				'Upper Limit': alert?.message_data?.usecase_payload?.upperLimit?.toFixed(2) ?? 'NA',
				'Lower Limit': alert?.message_data?.usecase_payload?.lowerLimit?.toFixed(2) ?? 'NA',
				'Days OOS': blockedCavityDatesDifference(
				alert?.message_data?.usecase_payload?.oos_date,
				alert?.message_data?.usecase_payload?.oos_resolved_date
			)
			}
			break
		case BLOCKED_CAVITY:
			data = {
				...commonObject,
				'Active Cavities': alert?.message_data?.usecase_payload?.activeCavities || "NA",
				'Blocked Cavities': (alert?.message_data?.usecase_payload?.blockedCavityCount || alert?.message_data?.usecase_payload?.blockedCavityCount === 0 ) ? alert?.message_data?.usecase_payload?.blockedCavityCount  : "NA",
				'Total Cavities': alert?.message_data?.usecase_payload?.totalCavities || "NA",
				'% Blocked Cavities': alert?.message_data?.usecase_payload?.totalCavities && (alert?.message_data?.usecase_payload?.blockedCavityCount || alert?.message_data?.usecase_payload?.blockedCavityCount === 0)
					? `${
							(alert?.message_data?.usecase_payload?.totalCavities *
								alert?.message_data?.usecase_payload?.blockedCavityCount) /
							100
						}`
					: 'NA',
				'Days With Cavities Blocked': blockedCavityDatesDifference(
				alert?.message_data?.usecase_payload?.openedDate,
				alert?.message_data?.usecase_payload?.closedDate
			) || "NA",
			}
			break

		case HOT_RUNNER_REFURBISHMENT:
			data = {
				...commonObject,
				'Current Health': alert.message_data?.usecase_payload?.status || '-',
				"Cycle Count":alert.message_data?.usecase_payload?.cycleCount || '-' ,
				"Days Until Projection":alert.message_data?.usecase_payload?.daysUntilProjection || '-' ,
				"Projected Date": moment().add(alert.message_data?.usecase_payload?.daysUntilProjection, "days").format("MM/DD/YYYY"),
				"Last PM Completion Date": alert.message_data?.usecase_payload?.lastPMCompletionDate || '-'
			}
			break

		case CLOSURE_MOLD_QUARTERLY_PM:
			data = {
				...commonObject,
				'Operating Hours': alert?.message_data?.OperatingHours || 'NA',
				'Current Health': alert.message_data?.CurrentHealth || '-'
			}
			break

		default: {
			data = { ...commonObject }
		}
	}
	return data
}

export const getModdedAlertHistoryExportForInjectionWorkOrderTicket = (
	alert: any
) => {
	return {
		'WO Number': alert?.woNumber,
		'Asset Number': alert?.AssetNumber,
		MsgType: alert?.MsgType,
		RunTime: alert?.RunTime,
		Status: alert?.Status,
		'Scheduled Completion Date': alert?.scheduledcompletionDate,
		'Schedule Start Date': alert?.schedulestartDate,
		'Target Completion Date': alert?.targetCompletionDate,
		'Target Start Date': alert?.targetStartDate,
		'Threshold Percentage': alert?.thresholdPercentage
	}
}

export const renderAlertHistoryTableForWorkOrderInjection = (alert: any) => {
	return {
		'WO Number': alert?.woNumber,
		'Asset Number': alert?.AssetNumber,
		'Msg Type': alert?.MsgType,
		'Run Time': alert?.RunTime,
		Status: alert?.Status
	}
}


const getHealthLevel = (messageData: any) => {
	switch (messageData?.alert_type) {
		case 'LGV Navigation Quality': {
			return messageData?.CurrentValue >= 85
				? 'Acceptable'
				: messageData?.CurrentValue >= 60 && messageData?.CurrentValue < 85
					? 'Monitor'
					: 'Danger'
		}
		case 'LGV Hydraulic System Health':
		case 'LGV Inverter System Health': {
			return messageData?.CurrentValue === 10
				? 'Acceptable'
				: messageData?.CurrentValue === 5
					? 'Monitor'
					: 'Danger'
		}
	}
}

export const renderAlertHistoryTableForLGV = (currentPlant:any,alert:any,currentTicket:any) => {
	return {
		Timestamp: getFormatedDate(
			currentPlant.plant_timezone,
			getTimeZoneTimeStamp(
				currentPlant.plant_timezone,
				alert.timestamp
			)
		),
		'Alert Type': alert.alert_type,
		'Lower Limit of Thresholds': alert?.message_data?.LowLimit,
		'Current Value': alert?.message_data?.CurrentValue,
		'Health Level': getHealthLevel(alert?.message_data),
		'Criticality Level': currentTicket?.criticality_level
	}
}
/**
 * Function to get Injection alert report type and route.
 * @param messageData
 * @returns
 */

export const getInjectionAlertReportTypeAndRoute = (messageData: any) => {
	let reportType = ''
	const reportTypeAndRoute = {
		subType: '',
		route: ''
	}

	const alertSubType = messageData?.AlertSubType || ''
	const alertSplit = alertSubType?.split('-')
	if (alertSplit && alertSplit.length > 0) {
		reportTypeAndRoute.subType = alertSplit[1]?.trim()
		reportType = alertSplit[0]?.trim()
	}

	if (reportType === 'Cycle Count Monitoring') {
		reportTypeAndRoute.route = 'cycle-count-monitoring'
	} else if (reportType === 'Cavity Monitoring') {
		reportTypeAndRoute.route = 'cavity-monitoring'
	} else if (reportType === 'Cycle Time Monitoring') {
		reportTypeAndRoute.route = 'cycle-time-monitoring'
	}

	/**
	 * Add sub routes based on type
	 */
	if (reportTypeAndRoute.subType === 'Cycle Time') {
		reportTypeAndRoute.subType = 'Cycle Time Tracking'
	} else if (reportTypeAndRoute.subType === 'Blocked Cavity') {
		reportTypeAndRoute.subType = 'Mold Blocked Cavity'
	}

	return reportTypeAndRoute
}

/**
 * Function to get days different from given date to current date.
 * @param date
 * @returns
 */
export const getDaysDifferenceWithCurrentDate = (date: string) => {
	if (date) {
		const date1 = moment(date)
		const date2 = moment()
		const days = date2.diff(date1, 'days')
		return days
	}
	return 'NA'
}

/**
 * Function to check only ticket type is blocked cavity.
 * @param currentTicket
 */
export const checkTicketIsBlockedCavity = (currentTicket: any) => {
	let isBlockedCavity = false
	// const alertSubType = currentTicket?.lastMessage?.message_data?.AlertSubType;
	const alertSubType = currentTicket?.usecase_payload?.alert_subtype

	const alertSplit = alertSubType?.split('-')

	let subType = ''
	if (alertSplit && alertSplit.length > 0) {
		subType = alertSplit[1]?.trim()
	}
	if (subType === 'Blocked Cavity') {
		isBlockedCavity = true
	} else {
		isBlockedCavity = false
	}

	return isBlockedCavity
}

/**
 * function to get closet ticket option boolean
 * @param currentTicket
 * @returns
 */
export const getCloseTicketOption = (currentTicket: any) => {
	// let enableOption = true

	// /**
	//  * No Need to show close ticket button option for blocked cavity reports
	//  */
	// if (currentTicket?.usecase_name === 'Injection Analytics') {
	// 	return false;
	// 	// enableOption = !checkTicketIsBlockedCavity(currentTicket)
	// } else {
	// 	enableOption = currentTicket?.status?.every((ele: any) => {
	// 		return ele?.device_status !== 'Closed'
	// 	})
	// }
	return currentTicket?.status?.every((ele: any) => {
		return ele?.device_status !== 'Closed'
	})
	// return enableOption
}

/**
 * Function to get Specific report alert messages.
 */
export const getSpecificReportMessages = (
	alertType: string,
	alertSubType: string,
	messages: any
) => {
	if (alertType === 'Injection Analytics') {
		const messagesData = messages?.filter(
			(message: any) => message?.message_data?.AlertSubType === alertSubType
		)
		return messagesData
	}
	return messages
}

/**
 * Function to form injection alert history bases on report type.
 */
export const getAlertHistoryExportForLGVHealth = (
	alert: any,
	ticketsList: any,
	plantsList: any,
	currentTicket: any,
	parentAsset: any,
	grandParentAsset: any
) => {
	const commonObject = getCommonAlertHistoryHistoryExportObjectForLgv(
		alert,
		plantsList,
		currentTicket,
		parentAsset,
	)
	return {
		...commonObject,
		'Lower Limit of Thresholds': alert?.message_data?.LowLimit,
		'Current Value': alert?.message_data?.CurrentValue,
		'Health Level': getHealthLevel(alert?.message_data),
		'Criticality Level': currentTicket?.criticality_level,
		'Ticket Relevance':
			currentTicket?.relevance === 1
				? 'Relevant'
				: currentTicket?.relevance === 2
					? 'Irrelevant'
					: ''
	}
}

export const getModdedArray = ({
	alert,
	levelsList,
	ticketsList,
	plantsList,
	currentTicket
}: any) => {
	const assetData = findAsset(levelsList, alert.asset_id)
	const parentAsset = findAsset(levelsList, assetData?.parent_asset)
	const grandParentAsset = findAsset(levelsList, parentAsset?.parent_asset)

	if (alert.alert_type === 'Aseptic - Valves') {
		return {
			'Ticket ID': ticketsList.tickets.find((ticket: any) => {
				return ticket._id === alert.ticket_id
			})?.ticketId_alias,
			'Alert Type': alert.alert_type,
			Tag: alert.tag,
			'Asset Name':
				levelsList.levels.find((data: any) => {
					return data._id === alert.asset_id
				})?.asset_alias ||
				levelsList.levels.find((data: any) => {
					return data._id === alert.asset_id
				})?.asset_name,
			'Device Name': alert.device_name,
			'Area/Line':
				grandParentAsset?.asset_alias || grandParentAsset?.asset_name || 'NA',
			'High Limit': alert.high_limit ? alert.high_limit : 'NA',
			'Current Value': alert.tag_value ? alert.tag_value : 'NA'
		}
	} else if (alert.alert_type === 'WQMS UseCase') {
		return {
			'Ticket ID':
				ticketsList.tickets.find((ticket: any) => {
					return ticket._id === alert.ticket_id
				}).ticketId_alias || '-',
			Timestamp: getFormatedDate(
				plantsList.plants.find((plant: any) => {
					return plant._id === currentTicket.plant_id
				}).plant_timezone,

				getTimeZoneTimeStamp(
					plantsList.plants.find((plant: any) => {
						return plant._id === currentTicket.plant_id
					}).plant_timezone,

					alert.timestamp
				)
			),
			Usecase: alert.alert_type || '-',
			Tag: alert.tag || '-',
			'Asset Name':
				levelsList.levels.find((data: any) => {
					return data._id === alert.asset_id
				}).asset_alias ||
				levelsList.levels.find((data: any) => {
					return data._id === alert.asset_id
				}).asset_name ||
				'-',
			'Device Name': alert.device_name || '-',
			Area: parentAsset?.asset_alias || parentAsset?.asset_name || 'NA' || '-',
			DataPoint: alert.message_data?.DataPoint || '-',
			'Alert Type': alert.wqms_alert_type || '-',
			'Upper Limit': alert.message_data?.HighLimit || '-',
			'Current Value': alert.message_data?.TagValueDouble || '-',
			'Lower Limit': alert.message_data?.LowLimit || '-',
			'Possible Causes':
				alert.message_data?.PossibleCauseArray.join(' ') || '-',
			'Corrective Actions':
				alert.message_data?.Reference1_Array.join(' ') || '-'
		}
	} else if (alert.alert_type === 'InHouse M&P') {
		return {
			'Ticket ID': ticketsList.tickets.find((ticket: any) => {
				return ticket._id === alert.ticket_id
			}).ticketId_alias,
			Timestamp: getFormatedDate(
				plantsList.plants.find((plant: any) => {
					return plant._id === currentTicket.plant_id
				}).plant_timezone,
				getTimeZoneTimeStamp(
					plantsList.plants.find((plant: any) => {
						return plant._id === currentTicket.plant_id
					}).plant_timezone,

					alert.timestamp
				)
			),
			'Alert Type': alert.alert_type || '-',
			Tag: alert.tag || '-',
			'Area/Line':
				grandParentAsset?.asset_alias || grandParentAsset?.asset_name || 'NA',
			'Asset Name': parentAsset?.asset_alias || parentAsset?.asset_name || 'NA',
			'Machine Name': assetData.asset_alias || assetData.asset_name || '-',
			'Device Name': alert.device_name || '-',
			// Sensor: findSensor(alert.tag)|| "-",
			'Current Health Status': alert.health_status || '-',
			'Previous Health Status': alert.message_data?.PreviousHealth || '-',
			'Possible Causes':
				alert.message_data?.PossibleCauses === ''
					? 'NA'
					: alert.message_data?.PossibleCauses.join(' ') || '-',
			'Corrective Actions':
				alert.message_data?.CorrectiveActions === ''
					? 'NA'
					: alert.message_data?.CorrectiveActions.split('#').join(' ') || '-',
			'Upper Threshold': alert.message_data?.UpperThreshold || '-',
			'Lower Threshold': alert.message_data?.LowerThreshold || '-',
			'Asset Hours': alert.message_data?.AssetHours || '-',
			'Blowmolder Hours': alert.message_data?.BlowmolderHours || '-'
		}
	} else if (alert.alert_type === 'Servo Drives') {
		return {
			'Ticket ID': ticketsList.tickets.find((ticket: any) => {
				return ticket._id === alert.ticket_id
			}).ticketId_alias,
			Timestamp: getFormatedDate(
				plantsList.plants.find((plant: any) => {
					return plant._id === currentTicket.plant_id
				}).plant_timezone,

				getTimeZoneTimeStamp(
					plantsList.plants.find((plant: any) => {
						return plant._id === currentTicket.plant_id
					}).plant_timezone,

					alert.timestamp
				)
			),
			'Alert Type': alert.alert_type || '-',
			Tag: alert.tag || '-',
			'Area/Line':
				grandParentAsset?.asset_alias || grandParentAsset?.asset_name || 'NA',
			'Asset Name': parentAsset?.asset_alias || parentAsset?.asset_name || 'NA',
			'Servo Name': assetData.asset_alias || assetData.asset_name || '-',
			'Device Name': alert.device_name || '-',
			// Sensor: findSensor(alert.tag)|| "-",
			'Current Health Status': alert.health_status || '-',
			'Previous Health Status': alert.message_data?.PreviousHealth || '-',
			'Possible Causes':
				alert.message_data?.PossibleCauses === ''
					? 'NA'
					: alert.message_data?.PossibleCauses.join(' ') || '-',
			'Corrective Actions':
				alert.message_data?.CorrectiveActions === ''
					? 'NA'
					: alert.message_data?.CorrectiveActions.split('#').join(' ') || '-'
		}
	} else if (alert.alert_type === 'AirLeaks UseCase') {
		return {
			'Ticket ID': ticketsList.tickets.find((ticket: any) => {
				return ticket._id === alert.ticket_id
			}).ticketId_alias,
			Timestamp: getFormatedDate(
				plantsList.plants.find((plant: any) => {
					return plant._id === currentTicket.plant_id
				}).plant_timezone,
				getTimeZoneTimeStamp(
					plantsList.plants.find((plant: any) => {
						return plant._id === currentTicket.plant_id
					}).plant_timezone,

					alert.timestamp
				)
			),
			'Alert Type': alert.alert_type || '-',
			Tag: alert.tag || '-',
			'Area/Line':
				grandParentAsset?.asset_alias ||
				grandParentAsset?.asset_name ||
				'NA' ||
				'-',
			'Asset Name':
				parentAsset?.asset_alias || parentAsset?.asset_name || 'NA' || '-',
			'Machine Name': assetData.asset_alias || assetData.asset_name || '-',
			'Device Name': alert.device_name || '-',
			// Sensor: findSensor(alert.tag)||"-",
			'Current Health Status': alert.health_status || '-',
			'Previous Health Status': alert.message_data?.PreviousHealth || '-',
			'Possible Causes': alert.message_data?.PossibleCausesString || '-',
			'Corrective Actions': alert.message_data?.CorrectiveActions || '-',
			'Current Health': alert.message_data?.CurrentHealth || '-',
			'Days with leakage': 'NA',
			// "Pipe Size": alert.message_data?.partNumber||"-",
			'Sensor Label': alert.message_data?.SerialNo || '-',
			'Daily Potential Savings': alert.message_data?.LowLimit || '-'
		}
	} else if (checkTicketTypeIsLGV(alert?.alert_type)) {
		return getAlertHistoryExportForLGVHealth(
			alert,
			ticketsList,
			plantsList,
			currentTicket,
			parentAsset,
			grandParentAsset
		)
	} else if (alert.alert_type === 'Removed LGV') {
		return {
			'Ticket ID': currentTicket.ticketId_alias || '-',
			WHSE: alert.message_data.FacilityCode,
			'LGV ID': alert.message_data.LgvId,
			Usecase: alert.alert_type || '-',
			Timestamp: getFormatedDate(
				plantsList.plants.find((plant: any) => {
					return plant._id === currentTicket.plant_id
				}).plant_timezone,

				getTimeZoneTimeStamp(
					plantsList.plants.find((plant: any) => {
						return plant._id === currentTicket.plant_id
					}).plant_timezone,

					alert.timestamp
				)
			),
			'Removal Time': alert.message_data.RemovedTime,
			'Removal Reason': alert.message_data.RemovedReason,
			'Last Checked': alert.message_data.LastCheckedTime
		}
	}
}

export const blockedCavityDatesDifference = (
	openDate: string,
	closeDate: string
) => {
	if (openDate && closeDate) {
		const startTime = new Date(openDate)
		const endTime = new Date(closeDate)
		const difference =
			(endTime.valueOf() - startTime.valueOf()) / (24 * 60 * 60 * 1000)
		return Math.ceil(difference)
	} else {
		// Date difference with current time
		const startTime = new Date(openDate)
		const endTime = new Date()
		const difference =
			(endTime.valueOf() - startTime.valueOf()) / (24 * 60 * 60 * 1000)
		return Math.ceil(difference)
	}
	return '-'
}

export const checkTicketIsLGV = (currentTicket:any) => {
	const subtype = currentTicket?.usecase_name
	return ["LGV Inverter System Health", "LGV Hydraulic System Health" , "LGV Battery System Health", "LGV Navigation Quality", "LGV PLS Sensors"]?.includes(subtype)
}

export const checkTicketTypeIsLGV = (subtype: string) => {
	return ["LGV Inverter System Health", "LGV Hydraulic System Health" , "LGV Battery System Health", "LGV Navigation Quality", "LGV PLS Sensors"]?.includes(subtype)
}
