import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useRef, useState } from "react";
import NotificationsActiveTwoToneIcon from "@material-ui/icons/NotificationsActiveTwoTone";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { experimentalStyled } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { formatDistance, subDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import HealthLevelCircularProgress from "./HealthLevelCircularProgress";
import {
  getFormatedDate,
  getTimeZoneTimeStamp,
} from "../../../../Utils/UtilFunctions/timeZoneFormatter";
import CIPHealthLevelCircularProgress from "./CIPHealthLevelCircularProgress";

const NotificationsBadge = experimentalStyled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 1)};
        min-width: 16px; 
        height: 16px;
        padding: 0;
        top:4px;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 1)};
            content: "";
        }
    }
`
);

function HeaderNotifications() {
  const dispatch = useDispatch();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const notificationsList = useSelector(
    (state: any) => state.notificationsList
  );
  const levelsList = useSelector((state: any) => state.levelsList);
  const plantsList = useSelector((state: any) => state.plantsList);
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const appMode = localStorage.getItem("appMode");

  function excludeAlertSubType(str: string) {
    const AlertSubTypesToExclude = [
      "Operating Hours 140%",
      "WO Complete",
      "Upcoming PM",
      "Counter Not Incrementing",
      "Counter Incrementing Again",
      "Gantt Total Hours",
      "Gantt Top Visualization",
      "Work Order Draft",
      "Work Order Complete",
    ];
    for (const word of AlertSubTypesToExclude) {
      if (str.includes(word)) {
        return false;
      }
    }
    return true;
  }

  // Exclude notifications Before returning
  const currentNotificationList = notificationsList.notifications
    ? notificationsList.notifications.filter((message: any) => {
        return message?.usecase_name === "Run Hours V2" &&
          message?.message_data?.AlertSubType
          ? excludeAlertSubType(message.message_data?.AlertSubType)
          : true;
      })
    : [];

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton
          data-testid="NotificationIcon"
          color="primary"
          ref={ref}
          onClick={handleOpen}
        >
          <NotificationsBadge
            badgeContent={
              currentNotificationList?.filter((ele: any) => {
                return ele.read === false;
              }).length
            }
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <NotificationsNoneOutlinedIcon sx={{ fontSize: "25px" }} />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ borderRadius: "8px" }}>
          <Box
            sx={{ p: 2 }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">Notifications</Typography>
            {/* <div style={{ float: "right", display: "inline" }}> */}
            <Typography
              data-testid="ClearNotificationsIcon"
              variant="h5"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch({ type: "CLEAR_NOTIFICATIONS" });
              }}
            >
              Clear Notifications
            </Typography>
            {/* </div> */}
          </Box>

          <List sx={{ p: 0 }}>
            {currentNotificationList?.length === 0 ? (
              <ListItem
                sx={{
                  p: 2,
                  minWidth: 350,
                  display: { xs: "block", sm: "flex" },
                }}
                style={{
                  backgroundColor: appMode === "Dark" ? "#232A58" : "white  ",
                }}
              >
                <Box flex="1">
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.secondary"
                  >
                    No new notifications
                  </Typography>
                </Box>
              </ListItem>
            ) : plantsList.plants.length > 0 && plantsList.loading === false ? (
              currentNotificationList?.map((notification: any) => {
                return (
                  <ListItem
                    data-testid="NotificationItem"
                    sx={{
                      padding: "5px 10px",
                      minWidth: 350,
                      display: { xs: "block", sm: "flex" },
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      style={{
                        border: "1px solid #E5E9EF",
                        borderRadius: "8px",
                        padding: "15px 10px",
                        backgroundColor: notification.read
                          ? ""
                          : appMode === "Dark"
                          ? "#121927"
                          : "#F6F8FB",
                      }}
                      flex="1"
                    >
                      <Box display="flex">
                        <div
                          style={{
                            display: "flex",
                            alignSelf: "center",
                            marginTop: "-15px",
                          }}
                        >
                          {notification.usecase_name === "Aseptic CIP" ? (
                            <CIPHealthLevelCircularProgress
                              currentValue={
                                notification.message_data.CurrentValue
                              }
                              upperThreshold={
                                notification.message_data.LowerThreshold
                              }
                              lowerThreshold={
                                notification.message_data.UpperThreshold
                              }
                            />
                          ) : // showing health level only for notificaiotns which have HLFlag===true for WQMS and utilities alerts only
                          notification.usecase_name === "WQMS UseCase" ||
                            notification.usecase_name === "Utilities CBM/PdM" ||
                            notification.usecase_name ===
                              "Aseptic - Process Room CCP" ||
                            notification.usecase_name === "Run Hours" ||
                            notification.usecase_name === "Run Hours V2" ||
                            notification.usecase_name ===
                              "Aseptic - Batch Room CCP" ||
                            notification.usecase_name ===
                              "Injection Analytics" ? (
                            notification.HLEnableFlag === true ? (
                              <HealthLevelCircularProgress
                                healthStatus={notification.health_status}
                                healthLevel={notification.health_level}
                              />
                            ) : (
                              <></>
                            )
                          ) : (
                            <HealthLevelCircularProgress
                              healthStatus={notification.health_status}
                              healthLevel={notification.health_level}
                            />
                          )}
                        </div>
                        <div>
                          <Typography
                            data-testid="NotificationLink"
                            sx={{ fontWeight: "bold" }}
                            style={{
                              margin: "0px 50px 0px 0px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              dispatch({
                                type: "READ_NOTIFICATION",
                                payload: notification,
                              });

                               if (notification.usecase_name === "Aseptic CIP") {
                                const messageId =
                                  notification.message_id === undefined
                                    ? notification._id
                                    : notification.message_id;
                                navigate(
                                  `/aseptic-cip/wash-alert/${messageId}`
                                );
                              } else if (
                                notification.ticket_id !== undefined &&
                                notification.ticket_id !== ""
                              ) {
                                navigate(
                                  `/maintenance/ticket-details/${notification.ticket_id}`
                                );
                              } else {
                                dispatch({
                                  type: "LOAD_SNACKBAR",
                                  payload: {
                                    message: "Ticket Not Found",
                                    type: "error",
                                    open: true,
                                  },
                                });
                              }
                              setOpen(false);
                            }}
                          >
                            {levelsList.levels.find((asset: any) => {
                              return asset._id === notification.asset_id;
                            })?.asset_alias ||
                              levelsList.levels.find((asset: any) => {
                                return asset._id === notification.asset_id;
                              })?.asset_name}
                          </Typography>

                          {notification.message_data.description !==
                            undefined &&
                          notification.message_data.description !== "" ? (
                            <Typography
                              component="div"
                              variant="body2"
                              color="text.secondary"
                              style={{
                                margin: "5px 0px",
                                fontSize: "14px",
                              }}
                            >
                              {notification.message_data.description}
                            </Typography>
                          ) : (
                            <></>
                          )}

                          <Typography
                            component="div"
                            variant="body2"
                            color="text.secondary"
                            style={{ fontSize: "12px" }}
                          >
                            {notification.timestamp !== undefined &&
                              notification.timestamp !== null &&
                              getFormatedDate(
                                plantsList.plants.find((plant: any) => {
                                  return plant._id === notification.plant_id;
                                }).plant_timezone,
                                getTimeZoneTimeStamp(
                                  plantsList.plants.find((plant: any) => {
                                    return plant._id === notification.plant_id;
                                  }).plant_timezone,
                                  notification.timestamp
                                )
                              )}
                          </Typography>
                        </div>
                      </Box>
                      <div style={{ display: "inline", float: "right" }}>
                        <ClearIcon
                          data-testid="RemoveNotificationIcon"
                          onClick={(event) => {
                            dispatch({
                              type: "REMOVE_NOTIFICATION",
                              payload: notification,
                            });
                          }}
                        />
                      </div>
                    </Box>
                  </ListItem>
                );
              })
            ) : (
              <></>
            )}
          </List>

          <Typography
            component="div"
            variant="body2"
            style={{
              fontSize: "14px",
              textAlign: "center",
              margin: "10px 0px",
              cursor: "pointer",
              color: "text.primary",
            }}
            onClick={() => {
              setOpen(false);
              navigate(`/maintenance/notifications`);
            }}
          >
            See all notifications
          </Typography>
        </div>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
