import { Component, ReactNode } from "react";
interface ErrorBoundaryProps {
  children: ReactNode;
}
interface ErrorBoundaryState {
  hasError: boolean;
}
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      //fallback UI
      return (
        <h3 style={{ margin: "25%", textAlign: "center" }}>
          Sorry, something went wrong.
        </h3>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
