import { HubConnectionBuilder } from "@microsoft/signalr";
import { env } from "../env";

export default class SignalRConnection {
  public static connectToSignalR = () => {
    let connection = new HubConnectionBuilder()
      .withUrl(env.signalrConnectionUrl,
        //   {
        //   transport: HttpTransportType.WebSockets
        // }
      )
      .withAutomaticReconnect()
      .build();
    return connection;
  };
}
