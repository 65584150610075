import Axios from "../../../../../Utils/AxiosConfig/axiosConfig";
export const getAllDownTimeTickets = async (
  payload: any,
  isUrl?: boolean
): Promise<any> => {
  try {
    let url = `/downtime/getSupplyChainTickets?page=${payload.page}&pageSize=${payload.limit}&dt_ticket_category=${payload.dt_ticket_category}&plant_id=${payload.plant_id}`;

    if (payload.dt_ticket_alias) {
      url = url + `&dt_ticket_alias=${payload.dt_ticket_alias}`;
    }

    if (payload.dt_ticket_type) {
      url = url + `&dt_ticket_type=${payload.dt_ticket_type}`;
    }

    if (payload.sort_by && payload.sort_order) {
      url =
        url + `&sort_by=${payload.sort_by}&sort_order=${payload.sort_order}`;
    }

    if (payload.dt_manufacturing_region) {
      url = url + `&dt_manufacturing_region=${payload.dt_manufacturing_region}`;
    }

    if (payload.dt_supply_chain_region) {
      url = url + `&dt_supply_chain_region=${payload.dt_supply_chain_region}`;
    }

    if (payload.dt_vendor) {
      url = url + `&dt_vendor=${payload.dt_vendor}`;
    }
    if (payload.dt_ticket_priority) {
      url = url + `&dt_ticket_priority=${payload.dt_ticket_priority}`;
    }

    if (payload.dt_requestor_id) {
      url = url + `&dt_requestor_id=${payload.dt_requestor_id}`;
    }
    if (payload.dt_ticket_status) {
      url = url + `&dt_ticket_status=${payload.dt_ticket_status}`;
    }
    if (payload.dt_assignee_name) {
      url = url + `&dt_assignee_name=${payload.dt_assignee_name}`;
    }
    if (payload.excel) {
      url = url + `&excel=true`;
    }
    if (isUrl) {
      return url;
    }
    const response = await Axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createDownTimeTicket = async (payload: any): Promise<any> => {
  try {
    let url: string = "downtime/createSupplyChain";
    const response: any = await Axios.post(url, payload);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getLevelTypes = async (): Promise<any> => {
  try {
    let url: string = "/levelTypes";
    const response: any = await Axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getFiltersList = async (id: string): Promise<any> => {
  try {
    let url: string = `downtime/getFiltersList/${id}`;
    const response: any = await Axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};
export const updateDownTimeTicket = async (payload: any): Promise<any> => {
  try {
    let url: string = `downtime/updateTicket`;
    const response: any = await Axios.put(url, payload);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getDowntimeticketDetails = async (
  ticketId: string
): Promise<any> => {
  try {
    let url: string = `downtime/ticketDetail/${ticketId}`;
    const response: any = await Axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateDownTimeTicketStatus = async (
  payload: any
): Promise<any> => {
  try {
    let url: string = `downtime/updateStatus`;
    const response: any = await Axios.put(url, payload);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteDownTimeTicket = async (ticketId: string): Promise<any> => {
  try {
    const url = `downtime/deleteDowntime/${ticketId}`;
    const response: any = await Axios.delete(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getChatComments = async (payload: any): Promise<any> => {
  try {
    let url: string = `chatrooms/${payload._id}`;
    const response: any = await Axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getDowntimTicketsAlerts = async (payload: any): Promise<any> => {
  try {
    let url = `/downtime/alerts/${payload.user_id}?page=${payload.page}&pageSize=${payload.limit}`;

    if (payload.search) {
      url = url + `&search=${payload.search}`;
    }

    if (payload.sort_by && payload.sort_order) {
      url =
        url + `&sort_by=${payload.sort_by}&sort_order=${payload.sort_order}`;
    }

    const response: any = await Axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const importDowntimeTickets = async (formData: any, user_id: string): Promise<any> => {
  try {
    let url = `/downtime/importFile/${user_id}`;
    const response: any = await Axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    return response;

  } catch (error) {
    throw error;
  }

}

export const updateChatComment = async (payload: any): Promise<any> => {
  try {
    let url: string = `chatrooms/updateChatroom/${payload._id}`;
    const response: any = await Axios.put(url, { ...payload.payload, "downTime": true });
    return response;
  } catch (error) {
    throw error;
  }
};
