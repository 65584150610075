import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function HealthLevelCircularProgress(props: any) {
  const [color, setColor] = React.useState("");
  const [boxShadow, setBoxShadow] = React.useState("");

  React.useEffect(() => {
    setColor(
      props.healthLevel >= 0 && props.healthLevel <= 2
        ? "rgba(214, 69, 80, 1)"
        : props.healthLevel >= 3 && props.healthLevel <= 4
        ? "rgba(227, 140, 37, 1)"
        : props.healthLevel >= 5 && props.healthLevel <= 7
        ? "rgba(227, 205, 4, 1)"
        : props.healthLevel >= 8 && props.healthLevel <= 10
        ? "rgba(147, 229, 48, 1)"
        : "rgba(128, 128, 128, 1)"
    );

    setBoxShadow(
      props.healthLevel >= 0 && props.healthLevel <= 2
        ? "inset 0 0 0px 3px rgba(214, 69, 80, 0.3)"
        : props.healthLevel >= 3 && props.healthLevel <= 4
        ? "inset 0 0 0px 3px rgba(227, 140, 37, 0.3)"
        : props.healthLevel >= 5 && props.healthLevel <= 7
        ? "inset 0 0 0px 3px rgba(227,205,4, 0.3)"
        : props.healthLevel >= 8 && props.healthLevel <= 10
        ? "inset 0 0 0px 3px rgba(147, 229, 48, 0.3)"
        : "inset 0 0 0px 3px rgba(128, 128, 128, 0.3)"
    );
  }, [props.healthLevel]);
  return (
    <>
      <Box
        style={{
          position: "relative",
          marginRight: "55px",
          display: "inline",
          top: "-13px",
        }}
      >
        <CircularProgress
          variant="determinate"
          style={{
            width: "43px",
            height: "43px",
            borderRadius: "100%",
            boxShadow: boxShadow,
            backgroundColor: "transparent",
            position: "absolute",
          }}
        />
        <CircularProgress
          variant="determinate"
          value={props.healthLevel < 0 ? 100 : props.healthLevel * -10}
          thickness={7}
          style={{
            color: color,
            width: "47px",
            height: "47px",
            borderRadius: "100%",
            position: "absolute",
            top: "-2px",
            left: "-2px",
            zIndex: "10",
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "6px",
            left:
              props.healthLevel === 10
                ? "11px"
                : props.healthLevel < 0
                ? "13px"
                : "16px",
          }}
        >
          <div>
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
              style={{ fontSize: "18px" }}
            >
              {props.healthLevel}
            </Typography>
          </div>
        </Box>
      </Box>
    </>
  );
}
