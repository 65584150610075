const tableTextStyles = {
	cellStyle: {
		// minWidth: 180,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: '100%'
	},
	headerStyle: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: '100%'
	}
}

export const injectionType = {
	CCM: {
		label: 'Cycle Count Monitoring',
		subType: {
			label1: 'Closure Mold Quarterly PM Tracking',
			label2: 'Preform Mold Cycle Count Tracking',
			label3: 'Closure Mold Lifetime Cycle Tracking'
		}
	},
	CM: {
		label: 'Cavity Monitoring',
		subType: {
			label1: 'Mold Blocked Cavity Tracking Report'
			// label2: "ThroughPut Analysis Blocked Cavity",
		}
	},
	CTM: {
		label: 'Cycle Time Monitoring',
		subType: {
			label1: 'Cycle Time Monitoring Report'
			// label2: "Throughput Analysis Cycle Time",
		}
	}
}

export const filterFields: any = {
	'Closure Mold Quarterly PM Tracking': [
		{
			label: 'Plant',
			type: 'select',
			value: null,
			option: [],
			key: 'PlantName'
		},
		{
			label: 'Machine Designation',
			type: 'select',
			value: null,
			option: [],
			key: 'MachineDesignation'
		}
	],
	'Preform Mold Cycle Count Tracking': [
		{
			label: 'Plant',
			type: 'select',
			value: null,
			option: [],
			key: 'PlantName'
		},
		{
			label: 'Machine Designation',
			type: 'select',
			value: null,
			option: [],
			key: 'MachineDesignation'
		}
	],
	'Closure Mold Lifetime Cycle Tracking': [
		{
			label: 'Plant',
			type: 'select',
			value: null,
			option: [],
			key: 'PlantName'
		},
		{
			label: 'Machine Designation',
			type: 'select',
			value: null,
			option: [],
			key: 'MachineDesignation'
		}
	],
	'Mold Blocked Cavity': [
		{
			label: 'Plant',
			type: 'select',
			value: null,
			option: [],
			key: 'PlantName'
		},
		{
			label: 'Machine Designation',
			type: 'select',
			value: null,
			option: [],
			key: 'MachineDesignation'
		},
		{
			label: 'No of Blocked Cavities',
			type: 'select',
			value: null,
			option: [],
			key: 'Last_Blocked_Cavities'
		}
		// {
		//   label: "Total Cavities",
		//   type: "range",
		//   value: null,
		//   option: [],
		//   key: "Last_TotalCavityValue",
		// },
		// {
		//   label: "Blocked Cavities",
		//   type: "range",
		//   value: null,
		//   option: [],
		//   key: "Last_Blocked_Cavities",
		// },
	],
	'Throughput Analysis Blocked Cavity': [
		{ label: 'Year', value: null, option: [], key: 'year' },
		{ label: 'Month', value: null, option: [], key: 'month' },
		{
			label: 'Machine Type',
			value: null,
			option: [],
			key: 'machine_type'
		},
		{ label: 'Total Cavities', value: null, option: [], key: 'total' },
		{ label: 'Blocked Cavities', value: null, option: [], key: 'blocked' }
	],
	'Cycle Time Tracking': [
		{
			label: 'Machine Designation',
			type: 'select',
			value: null,
			option: [],
			key: 'MachineName'
		}
	],
	'Throughput Analysis Cycle Time': [
		{ label: 'Plant', value: null, option: [], key: 'plant_name' },
		{
			label: 'Machine Type',
			value: null,
			option: [],
			key: 'machine_type'
		},
		{
			label: 'Machine Name',
			value: null,
			option: [],
			key: 'machine_name'
		},
		{ label: 'Year', value: null, option: [], key: 'year' },
		{ label: 'Region', value: null, option: [], key: 'region' }
	]
}

export const tabOption: any = {
	'Cycle Count Monitoring': [
		{
			label: 'Closure Mold Quarterly PM Tracking',
			key: 'closure-mold-quarterly-pm'
		},
		{
			label: 'Preform Mold Cycle Count Tracking',
			key: 'hot-runner-refurbishment'
		},
		{
			label: 'Closure Mold Lifetime Cycle Tracking',
			key: 'closure-mold-life-time-cycle'
		}
	],
	'Cavity Monitoring': [
		{
			label: 'Mold Blocked Cavity Tracking Report',
			key: 'mold-blocked-cavity-tracking-report'
		}
		// {
		//   label: "ThroughPut Analysis Blocked Cavity",
		//   key: "throughput-analysis-blocked-cavity",
		// },
	],
	'Cycle Time Monitoring': [
		{
			label: 'Cycle Time Monitoring Report',
			key: 'cycle-time-monitoring-report'
		}
		// {
		//   label: "Throughput Analysis Cycle Time",
		//   key: "throughput-analysis-cycle-time",
		// },
	]
}

export const reportColumns: any = {
	'Closure Mold Quarterly PM Tracking': [
		{
			label: 'Plant',
			key: 'InjectionCycleCount.PlantName',
			dbColumnName: 'InjectionCycleCount.PlantName',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Machine Name',
			key: 'machine_name',
			dbColumnName: 'machine_name',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Machine Designation',
			key: 'InjectionCycleCount.MachineDesignation',
			dbColumnName: 'InjectionCycleCount.MachineDesignation',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Cycle Count Since Last Quarterly PM',
			key: 'cycle_count_text',
			dbColumnName: 'cycle_count',
			sortPriorityKey: 'cycle_count',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Next Predicted PM Date',
			key: 'next_predicted',
			dbColumnName: 'next_predicted',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'No. of Days Until Due',
			key: 'until_due_text',
			dbColumnName: 'until_due',
			sortPriorityKey: 'until_due',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'No. of Days Past Due',
			key: 'past_due_text',
			dbColumnName: 'past_due',
			sortPriorityKey: 'past_due',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		}
	],
	'Preform Mold Cycle Count Tracking': [
		{
			label: 'Plant',
			key: 'InjectionCycleCount.PlantName',
			dbColumnName: 'InjectionCycleCount.PlantName',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Machine Name',
			key: 'machine_name',
			dbColumnName: 'machine_name',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Machine Designation',
			key: 'InjectionCycleCount.MachineDesignation',
			dbColumnName: 'InjectionCycleCount.MachineDesignation',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Cold Half Project Number',
			key: 'cold_half_project_number',
			dbColumnName: 'cold_half_project_number',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Hot Runner Project Number',
			key: 'hot_runner_project_number',
			dbColumnName: 'hot_runner_project_number',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Cold Half Cycle Count',
			key: 'cold_half_cycle_count_text',
			dbColumnName: 'cold_half_cycle_count',
			sortPriorityKey: 'cold_half_cycle_count',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Hot Runner Cycle Count',
			key: 'hot_runner_cycle_count_text',
			dbColumnName: 'hot_runner_cycle_count',
			sortPriorityKey: 'hot_runner_cycle_count',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Predicted Next CH Maintenance',
			key: 'predicted_next_CH_Maintenance_text',
			sortPriorityKey: 'predicted_next_CH_Maintenance_text',
			dbColumnName: 'predicted_next_CH_Maintenance',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Predicted Next HR Maintenance',
			key: 'predicted_next_HR_Maintenance_text',
			dbColumnName: 'predicted_next_HR_Maintenance',
			sortPriorityKey: 'predicted_next_HR_Maintenance',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		}
	],
	'Closure Mold Lifetime Cycle Tracking': [
		{
			label: 'Plant',
			key: 'InjectionCycleCount.PlantName',
			dbColumnName: 'InjectionCycleCount.PlantName',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Machine Name',
			key: 'InjectionCycleCount.MachineName',
			dbColumnName: 'InjectionCycleCount.MachineName',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Machine Designation',
			key: 'InjectionCycleCount.MachineDesignation',
			dbColumnName: 'InjectionCycleCount.MachineDesignation',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Cold Half Project Number',
			key: 'InjectionCycleCount.ColdHalfProjectNumber',
			dbColumnName: 'InjectionCycleCount.ColdHalfProjectNumber',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Hot Runner Project Number',
			key: 'InjectionCycleCount.HotRunnerProjectNumber',
			dbColumnName: 'InjectionCycleCount.HotRunnerProjectNumber',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Current Mold Cycle Count',
			key: 'InjectionCycleCount.CumulativeMoldCyclesTotalsResetFinalText',
			dbColumnName: 'InjectionCycleCount.CumulativeMoldCyclesTotalsResetFinal',
			sortPriorityKey:
				'InjectionCycleCount.CumulativeMoldCyclesTotalsResetFinal',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		}
	],
	'Mold Blocked Cavity': [
		{
			label: 'Plant',
			key: 'InjectionBlockedCavities.PlantName',
			dbColumnName: 'InjectionBlockedCavities.PlantName',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Machine Name',
			key: 'InjectionBlockedCavities.MachineName',
			dbColumnName: 'InjectionBlockedCavities.MachineName',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Machine Designation',
			key: 'InjectionBlockedCavities.MachineDesignation',
			dbColumnName: 'InjectionBlockedCavities.MachineDesignation',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Latest Date Value',
			key: 'InjectionBlockedCavities.Date',
			dbColumnName: 'InjectionBlockedCavities.Date',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Last Total Cavity Value',
			key: 'InjectionBlockedCavities.Last_TotalCavityValue',
			dbColumnName: 'InjectionBlockedCavities.Last_TotalCavityValue',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Last Active Cavity Value',
			key: 'InjectionBlockedCavities.Last_ActiveCavityValue',
			dbColumnName: 'InjectionBlockedCavities.Last_ActiveCavityValue',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Blocked Cavities',
			key: 'InjectionBlockedCavities.Last_Blocked_Cavities',
			dbColumnName: 'InjectionBlockedCavities.Last_Blocked_Cavities',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: '%Blocked Cavities',
			key: 'InjectionBlockedCavities.Blocked_CavitiesText',
			dbColumnName: 'InjectionBlockedCavities.Blocked_Cavities',
			sortPriorityKey: 'InjectionBlockedCavities.Blocked_Cavities',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		}
	],
	'Cycle Time Tracking': [
		{
			label: 'Time Stamp',
			key: 'time_stamp',
			dbColumnName: 'time_stamp',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Hourly Average Cycle Time',
			key: 'avg_cycle_time',
			dbColumnName: 'avg_cycle_time',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		},
		{
			label: 'Machine Cycle Number',
			key: 'cycle_no',
			dbColumnName: 'cycle_no',
			cellStyle: tableTextStyles.cellStyle,
			headerStyle: tableTextStyles.headerStyle
		}
	]
}

export const tabChangeValue: any = {
	'Cycle Count Monitoring': [
		'Closure Mold Quarterly PM Tracking',
		'Preform Mold Cycle Count Tracking',
		'Closure Mold Lifetime Cycle Tracking'
	],
	'Cavity Monitoring': [
		'Mold Blocked Cavity'
		// "Throughput Analysis Blocked Cavity",
	],
	'Cycle Time Monitoring': [
		'Cycle Time Tracking'
		// "Throughput Analysis Cycle Time",
	]
}

export const tabObjects: any = {
	'Cycle Count Monitoring': {
		tabValue: 2,
		label: 'Closure Mold Lifetime Cycle Tracking'
	},
	'Cavity Monitoring': {
		tabValue: 1,
		label: 'Throughput Analysis Blocked Cavity'
	},
	'Cycle Time Monitoring': {
		tabValue: 1,
		label: 'Throughput Analysis Cycle Time'
	}
}

// REPORTS NAME
export const CLOSURE_MOLD_QUARTERLY_PM_TRACKING =
	'Closure Mold Quarterly PM Tracking'
export const PREFORM_MOLD_CYCLE_COUNT_TRACKING =
	'Preform Mold Cycle Count Tracking'
export const CLOSURE_MOLD_LIFETIME_CYCLE_TRACKING =
	'Closure Mold Lifetime Cycle Tracking'
export const CURRENT_COLD_HALF_CYCLE_COUNT = 'Current Cold Half Cycle Count'
export const CURRENT_HOT_RUNNER_CYCLE_COUNT = 'Current Hot Runner Cycle Count'

// CUBE CONSTANTS
// CYCLE COUNT
export const INJECTION_CYCLE_COUNT = 'InjectionCycleCount'
export const ICT_MN = 'InjectionCycleCount.MachineName'
export const ICT_MD = 'InjectionCycleCount.MachineDesignation'
export const ICT_HMI = 'InjectionCycleCount.HrMaintenanceInterval'
export const ICT_HRPN = 'InjectionCycleCount.HotRunnerProjectNumber'
export const ICT_CHPN = 'InjectionCycleCount.ColdHalfProjectNumber'
export const ICT_CCPNCHMID =
	'InjectionCycleCount.ClosurePredictedNextColdHalfMaintenanceinDays'
export const ICT_CPNCHMID =
	'InjectionCycleCount.PredictedNextColdHalfMaintenanceinDays'
export const ICT_PNHRMID =
	'InjectionCycleCount.PredictedNextHotRunnerMaintenanceinDays'
export const ICT_CCHRTRF =
	'InjectionCycleCount.CumulativeColdHalfTotalsResetFinal'
export const ICT_CQTRF =
	'InjectionCycleCount.CumulativeQuarterlyTotalsResetFinal'
export const ICT_CHRTRF =
	'InjectionCycleCount.CumulativeHotRunnerTotalsResetFinal'
export const ICT_CMCTRF =
	'InjectionCycleCount.CumulativeMoldCyclesTotalsResetFinal'
// BLOCKED CAVITY
export const BLOCKED_CAVITY = 'InjectionBlockedCavities'
export const IBC_LTCV = 'InjectionBlockedCavities.Last_TotalCavityValue'
export const IBC_MN = 'InjectionBlockedCavities.MachineName'
export const IBC_PN = 'InjectionBlockedCavities.PlantName'
export const IBC_MD = 'InjectionBlockedCavities.MachineDesignation'
export const IBC_LACV = 'InjectionBlockedCavities.Last_ActiveCavityValue'
export const IBC_LBC = 'InjectionBlockedCavities.Last_Blocked_Cavities'
export const IBC_BC = 'InjectionBlockedCavities.Blocked_Cavities'
export const IBC_FN = 'InjectionBlockedCavities.facilityName'

// QUERIES
export const CCM_CLOSURE_MOLD_QUARTERLY = 'ccm_closure_mold_quarterly'
export const CCM_HOT_RUNNER_REFURBISHMENT = 'ccm_hot_runner_refurbishment'
export const CCM_LIFE_TIME_TRACKING = 'ccm_life_time_tracking'
export const CM = 'cavity_monitoring'

// GENERAL
export const INIT_PAGINATION_OPTIONS: {
	page: number
	limit: number
} = {
	page: 1,
	limit: 5
}
export const ROWS_PER_PAGE_OPTIONS: number[] = [5, 10, 25, 50]
export const LOAD_SNACKBAR = 'LOAD_SNACKBAR'
export const DEFAULT_SNACKBAR_STATE = {
	message: 'Something went wrong, failed to fetch cube data!',
	type: 'error',
	open: true
}
export const MACHINE_NAME = 'Machine Name'
export const COLUMN_CHART = 'Column Chart'
export const NUMBER_OF_CYCLES = 'Number of Cycles'
export const NO_DATA_FOUND = 'No data found'
export const TOTAL_CAVITIES = 'Total Cavities'
export const BLOCKED_CAVITIES = 'Blocked Cavities'
export const NUMBER_OF_BLOCKED_CAVITIES = 'Number of Blocked Cavities' 

export const CTM_ACTS = 'Average Cycle Time (Sec)'
export const CTM_STS = 'Standard Time (Sec)'
export const CTM_LTS = 'Lower Tolerance (Sec)'
export const CTM_UTS = 'Upper Tolerance (Sec)'

export const INJECTION_CYCLE_TIME = 'InjectionCycleTime'
export const FETCH_CTM_MACHINE_NAME_DATA = 'FETCH_CTM_MACHINE_NAME_DATA'
export const CTM_MNFD = 'ctm_machine_name_filter_drawer'
export const CTM_MACHINE_NAME = 'MachineName'
export const CTM_MACHINE_DESIGNATION = 'Machine Designation'

export const CYCLE_TIME = 'Cycle Time'
export const TIME_STAMP = 'Time Stamp'
export const CTM_ACT = 'Average Cycle Time'
export const CTM_ST = 'Standard Time'
export const CTM_LT = 'Lower Tolerance'
export const CTM_HT = 'Higher Tolerance'
export const CTM_LINE_CHART = 'Line Chart'

export const FETCH_CTM_VISUAL_DATA = 'FETCH_CTM_VISUAL_DATA'
export const CTM_MACHINE_NAME_LOWER_CASE = 'machineName'
export const CTM_VISUAL = 'ctm_visual'
export const FETCH_CTM_TABLE_DATA = 'FETCH_CTM_TABLE_DATA'
export const CTM_TABLE = 'ctm_table'
export const CTM = 'Cycle Time Monitoring'
export const CAVITY_MONITORING = 'Cavity Monitoring'
