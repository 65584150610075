import { FC, ReactNode, useState } from 'react'
import { experimentalStyled } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { Outlet } from 'react-router-dom'
import Sidebar from '../Sidebar'
import Header from '../Header'
import SideNav from '../Sidenav'

interface SidebarWithHeaderLayoutProps {
	children?: ReactNode
}

const MainWrapper = experimentalStyled(Box)<{open: boolean}>(
	({ theme, open }) => `
  flex: 1 1 auto;
  display: flex;
  height: 100%;
  padding-left: ${open ? "320px" : "80px"};`
//   @media (min-width: ${theme.breakpoints.values.lg}px) {}
)

const MainContent = experimentalStyled(Box)(
	({ theme }) => `
  margin-top: ${theme.header.height};
  flex: 1 1 auto;
  overflow: auto;
  `
)

const SidebarWithHeaderLayout: FC<SidebarWithHeaderLayoutProps> = () => {
	
	const [open, setOpen] = useState(false)

	const toggleCTA = (action: string) => {
		setOpen(action === "open" ? true : false)
	}

	return (
		<>
			{/* <Sidebar /> */}
			<SideNav toggleAction={toggleCTA} />
			<MainWrapper open={open}>
				<Header />
				<MainContent>
					<Outlet />
				</MainContent>
			</MainWrapper>
		</>
	)
}

export default SidebarWithHeaderLayout
