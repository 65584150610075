export const loadSnackbar = (
  message = "Something went wrong!",
  type = "error"
) => ({
	type: 'LOAD_SNACKBAR',
	payload: {
		message,
		type,
		open: true
	}
})

export const lgvHealthUseCaseRoutesCode = ['lgv-nav', 'lgv-ish', 'lgv-hsh']

export const lgvHealthUseCaseList = [
	'LGV Navigation Quality',
	'LGV Hydraulic System Health',
	'LGV Inverter System Health'
]
