import { CubejsApi } from '@cubejs-client/core'
import moment from 'moment'
import { Dispatch } from 'react'
import { DEFAULT_SNACKBAR_STATE, LOAD_SNACKBAR } from '../constants'

/**
 * Function to filter reports data.
 * @param data  // [{plant_name:"Houston Plant", desc:""}]
 * @param filters  // ie:{plant_name:"Houston Plant"}
 *
 */
export const filterReportsData = (
	data: any,
	filters: any,
	reportsCube: string
): any => {
	return data.filter((item: any) => {
		for (const key in filters) {
			const filter = filters[key]
			const itemValue = item[`${reportsCube}.${key}`]
            if(filter?.type === 'select' && filter?.label === "No of Blocked Cavities"){
				if(parseInt(itemValue) !== parseInt(filter?.value)){
					return false;
				}
				return true;
			}
			else if (filter?.type === 'select' && itemValue !== filter?.value) {
				return false
			} else if (filter?.type === 'range') {
				const range = filter?.value?.match(/\d+/g)
				const value = parseFloat(item[`${reportsCube}.${key}`])
				if (!(value >= range[0] && value <= range[1])) {
					return false
				}
			}
		}

		return true
	})
}

/**
 * Function to sort reports data
 * @param data
 * @param sortObject
 * @returns
 */

export const sortReportsData = (data: any, sortObject: any): any => {
	let sortKey: any = ''
	let sortOrder: any = ''

	if (
		typeof sortObject === 'object' &&
		sortObject !== null &&
		'sort' in sortObject
	) {
		const sort = sortObject['sort']
		sortKey = Object.keys(sort)[0]
		sortOrder = sort[sortKey]
	} else {
		sortKey = sortObject
		sortOrder = 1
	}

	const sortedData = [...data].sort((a, b) => {
		let aValue = a[sortKey]
		let bValue = b[sortKey]

		if (
			sortKey === 'time_stamp' ||
			sortKey === 'InjectionBlockedCavities.Date' ||
			sortKey === 'next_predicted'
		) {
			const aValueMoment = moment(aValue)
			const bValueMoment = moment(bValue)
			if (aValueMoment.isBefore(bValueMoment)) {
				return -1 * sortOrder
			} else if (aValueMoment.isAfter(bValueMoment)) {
				return 1 * sortOrder
			}
			return 0

			// Kept code for reference
			// if (moment(aValue).valueOf() < moment(bValue).valueOf()) {
			//   return -1 * sortOrder;
			// }

			// if (moment(aValue).valueOf() > moment(bValue).valueOf()) {
			//   return 1 * sortOrder;
			// }
			// return 0;
		} else {
			const isNumeric = (value: any) => /^-?\d*\.?\d+$/.test(value)

			// Convert strings containing only digits to numbers
			if (isNumeric(aValue) && isNumeric(bValue)) {
				aValue = parseFloat(aValue)
				bValue = parseFloat(bValue)
			} else {
				aValue = aValue?.toString()
				bValue = bValue?.toString()
			}

			if (aValue < bValue) return -1 * sortOrder
			if (aValue > bValue) return 1 * sortOrder
			return 0
		}
	})

	return sortedData
}

export const CLOSURE_MOLD_QUARTERLY_PM =
	'Cycle Count Monitoring - Closure Mold Quarterly PM Tracking'
export const HOT_RUNNER_REFURBISHMENT =
	'Cycle Count Monitoring - Hot Runner Refurbishment'
export const BLOCKED_CAVITY = 'Cavity Monitoring - Blocked Cavity'
export const CYCLE_TIME = 'Cycle Time Monitoring - Cycle Time'
export const MOLD_BLOCKED_CAVITY = 'Mold Blocked Cavity'
export const CYCLE_TIME_TRACKING_TABLE = 'Cycle Time Tracking'
export const CYCLE_TIME_TRACKING_VISUAL = 'Cycle Time Tracking Visual'
export const CLOSURE_MOLD_LIFE_TIME_CYCLE =
	'Closure Mold Lifetime Cycle Tracking'

const INJECTION_CYCLE_TIME = 'InjectionCycleTime'

export const tableMapper = (dashboard: string, el: any) => {
	const truncateTo2Digits = (el: string) => Math.trunc(Number(el) * 100) / 100

	switch (dashboard) {
		case MOLD_BLOCKED_CAVITY:
			return {
				'InjectionBlockedCavities.MachineName':
					el['InjectionBlockedCavities.MachineName'],
				'InjectionBlockedCavities.Date': moment(
					el['InjectionBlockedCavities.Date']
				).format('MM/DD/YYYY'),
				'InjectionBlockedCavities.MachineDesignation':
					el['InjectionBlockedCavities.MachineDesignation'],
				'InjectionBlockedCavities.PlantName':
					el['InjectionBlockedCavities.PlantName'] ?? '-',
				'InjectionBlockedCavities.Last_ActiveCavityValue': Number(
					el['InjectionBlockedCavities.Last_ActiveCavityValue']
				),
				'InjectionBlockedCavities.Last_TotalCavityValue': Number(
					el['InjectionBlockedCavities.Last_TotalCavityValue']
				),
				'InjectionBlockedCavities.Last_Blocked_Cavities': Number(
					el['InjectionBlockedCavities.Last_Blocked_Cavities']
				),
				'InjectionBlockedCavities.Blocked_Cavities': Number(
					el['InjectionBlockedCavities.Blocked_Cavities']
				),
				'InjectionBlockedCavities.Blocked_CavitiesText': `${Number(
					el['InjectionBlockedCavities.Blocked_Cavities']
				)}%`
			}
		case CYCLE_TIME_TRACKING_VISUAL:
			return {
				avg_cycle_time: truncateTo2Digits(
					el[`${INJECTION_CYCLE_TIME}.AverageCycleTimeseconds`]
				),
				lower_tolerance: truncateTo2Digits(
					el[`${INJECTION_CYCLE_TIME}.LowerToleranceDynamic`]
				),
				standard_time: truncateTo2Digits(
					el[`${INJECTION_CYCLE_TIME}.TargetCycleTimeseconds`]
				),
				upper_tolerance: truncateTo2Digits(
					el[`${INJECTION_CYCLE_TIME}.UpperToleranceDynamic`]
				),
				time_stamp: el[`${INJECTION_CYCLE_TIME}.timeStamp`]
			}
		case CYCLE_TIME_TRACKING_TABLE:
			return {
				avg_cycle_time:
					truncateTo2Digits(
						el[`${INJECTION_CYCLE_TIME}.AverageCycleTimeSecondsFilteredSC`]
					) ?? '-',
				cycle_no: el[`${INJECTION_CYCLE_TIME}.MachineCycleNumber`]
					? Number(el[`${INJECTION_CYCLE_TIME}.MachineCycleNumber`])
					: '-',
				time_stamp:
					moment(el[`${INJECTION_CYCLE_TIME}.timeStamp`]).format(
						`MM/DD/YYYY hh:mm:ss A`
					) ?? '-'
			}
		case CLOSURE_MOLD_LIFE_TIME_CYCLE:
			return {
				'InjectionCycleCount.PlantName':
					el['InjectionCycleCount.PlantName'] ?? '-',

				'InjectionCycleCount.MachineName':
					el['InjectionCycleCount.MachineName'],

				'InjectionCycleCount.MachineDesignation':
					el['InjectionCycleCount.MachineDesignation'] ?? '-',

				'InjectionCycleCount.ColdHalfProjectNumber':
					el['InjectionCycleCount.ColdHalfProjectNumber'] ?? '-',
					
				'InjectionCycleCount.HotRunnerProjectNumber': el[
					'InjectionCycleCount.HotRunnerProjectNumber'
				]
					? Number(el['InjectionCycleCount.HotRunnerProjectNumber'])
					: '-',

				'InjectionCycleCount.CumulativeMoldCyclesTotalsResetFinal': el[
					'InjectionCycleCount.CumulativeMoldCyclesTotalsResetFinal'
				]
					? Number(
						el['InjectionCycleCount.CumulativeMoldCyclesTotalsResetFinal']
					).toFixed(0)
					: '-',
				'InjectionCycleCount.CumulativeMoldCyclesTotalsResetFinalText': el[
					'InjectionCycleCount.CumulativeMoldCyclesTotalsResetFinal'
				]
					? formatNumberWithCommas(
						Number(
							el['InjectionCycleCount.CumulativeMoldCyclesTotalsResetFinal']
						).toFixed(0)
					)
					: '-'
			}

		default:
			return {}
	}
}

// TODO
export const generateOptionLabels = (
	minValue: number,
	maxValue: number,
	type: string
) => {
	const labels: any = []

	switch (type) {
		case type === 'Total Cavities' || 'Blocked Cavities': {
			const range = maxValue - minValue

			if (!isFinite(minValue) || !isFinite(maxValue) || range === 0) {
				return []
			} else if (range <= 1) {
				const obj: any = {}
				obj.id = 0
				obj.label = 'From 0 To 1'
				obj.minValue = 0
				obj.maxValue = 1
				labels.push(obj)
			} else {
				const step = range / 4
				for (let i = 0; i < 4; i++) {
					const start = Math.ceil(minValue + i * step).toFixed(0)
					const end = Math.floor(minValue + (i + 1) * step).toFixed(0)

					const obj: any = {}
					obj.id = i
					obj.label = `From ${start} To ${end}`
					obj.minValue = start
					obj.maxValue = end
					labels.push(obj)
				}
			}

			return labels
		}
		case 'No of Blocked Cavities': {
			// Generate an array value from start to end
			for (let i = minValue; i <= maxValue; i++) {
				labels.push({ id: i, label: String(i) })
			}
			return labels
		}
		default:
			return labels
	}
}

/**
 * Function to calculate/predict next maintenance date 
 * @param date 
 * @returns 
 */

export const predictNextMaintenanceDate = (date: string, type:string) => {
  const predictDate = moment(date, 'MM-DD-YYYY');
  const currentDate = moment();

	// Find the difference in days
	const daysDifference = predictDate.diff(currentDate, 'days');
	if (type === "ch_maintenance" &&  daysDifference > 1095) {
    	return moment().add(1095, "days").format("MM-DD-YYYY");
  	} else if (type === "hr_maintenance" && daysDifference > 365) {
		return moment().add(365, "days").format("MM-DD-YYYY");
    }

  return date;
};

/**
 * Function to calculate CH Maintenance
 * @param maintenanceFinalResult
 * @param maintenanceCHdDate
 * @returns
 */

export const calculateCHMaintenance = (
	maintenanceFinalResult: number,
	maintenanceCHdDate: string
) => {
	let _predicted_next_CH_Maintenance_text = ''
	maintenanceCHdDate = predictNextMaintenanceDate(
    maintenanceCHdDate,
    "ch_maintenance"
  );

	if (maintenanceFinalResult <= 12000000) {
		_predicted_next_CH_Maintenance_text = `12M in ` + maintenanceCHdDate
	} else if (
		maintenanceFinalResult > 12000000 &&
		maintenanceFinalResult <= 24000000
	) {
		_predicted_next_CH_Maintenance_text = `24M in ` + maintenanceCHdDate
	} else {
		_predicted_next_CH_Maintenance_text = 'Condition Based'
	}

	return _predicted_next_CH_Maintenance_text
}

/**
 * function calculate HR Maintenance
 * @param hrMaintenanceFinalResult
 * @param hrMaintenanceInterval
 * @param hrMaintenanceDate
 * @returns
 */
export const calculateHRMaintenance = (
	hrMaintenanceFinalResult: number,
	hrMaintenanceInterval: number,
	hrMaintenanceDate: string
) => {
	let _predicted_next_HR_Maintenance_text = ''
	let colorCode = ''
	hrMaintenanceDate = predictNextMaintenanceDate(
    hrMaintenanceDate,
    "hr_maintenance"
  );

	if (
		hrMaintenanceFinalResult <= hrMaintenanceInterval &&
		hrMaintenanceInterval === 4000000
	) {
		_predicted_next_HR_Maintenance_text = `4M in ` + hrMaintenanceDate
		colorCode = '#1DAC56'
	} else if (
		hrMaintenanceFinalResult > hrMaintenanceInterval &&
		hrMaintenanceInterval === 4000000
	) {
		_predicted_next_HR_Maintenance_text = 'Immediate'
		colorCode = '#A91717'
	} else if (
		hrMaintenanceFinalResult <= hrMaintenanceInterval &&
		hrMaintenanceInterval === 5000000
	) {
		_predicted_next_HR_Maintenance_text = `5M in ` + hrMaintenanceDate
		colorCode = '#1DAC56'
	} else if (
		hrMaintenanceFinalResult > hrMaintenanceInterval &&
		hrMaintenanceInterval === 5000000
	) {
		_predicted_next_HR_Maintenance_text = 'Immediate'
		colorCode = '#A91717'
	}

	return {
		_predicted_next_HR_Maintenance_text: _predicted_next_HR_Maintenance_text,
		colorCode: colorCode
	}
}

/**
 * Function to format number with commas
 * @param value
 * @returns
 */

export const formatNumberWithCommas = (value: number | string) => {
	return value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * Function to extract unique values from objects based on a specific property
 * @param arr
 * @param property
 * @returns
 */
export const extractUniqueValues = (arr: any, property: string) => {
	const uniqueValues: any = []
	const uniquePlants: any = []
	arr?.forEach((obj: any, index: number) => {
		// Checking plant name not equal to -, null, "".
		if (
			obj[property] !== '-' &&
			obj[property] !== null &&
			obj[property] !== ''
		) {
			if (uniqueValues?.indexOf(obj[property]) === -1) {
				uniqueValues.push(obj[property])
				uniquePlants.push({
					id: index,
					label: obj[property]
				})
			}
		}
	})

	// Sorting unique plants names to ASC
	uniquePlants.sort((a: any, b: any) =>
		a.label > b.label ? 1 : b.label > a.label ? -1 : 0
	)
	return uniquePlants
}

// Function to Round off Decimal Places
export const roundToDecimalPlaces = (val: number) => {
	const decimalPlaces: number = (val.toString().split('.')[1] || '').length - 1
	const multiplier: number = Math.pow(10, decimalPlaces)
	return Math.round(val * multiplier) / multiplier
}

// Cube JS API CALL
interface LoadSnackbarAction {
	type: string
	payload: {
		message: string
		type: string
		open: boolean
	}
}

export const getDataFromCube = async (
	query: any,
	cubejsApi: CubejsApi,
	dispatch: Dispatch<LoadSnackbarAction>
) => {
	try {
		const resultSet = await cubejsApi.load(query)
		const fetchedData = resultSet
			.serialize()
			.loadResponse.results.map(({ data }: any) => data)
		return fetchedData
	} catch (err) {
		dispatch({
			type: LOAD_SNACKBAR,
			payload: {
				message: DEFAULT_SNACKBAR_STATE?.message,
				type: 'error',
				open: true
			}
		})
	}
}

export const filterChangeOutMachine = (changeOutMachines:any, cubeName: string) => {
	// Create a Set for faster lookup of MachineName
	const inputSet = new Set(changeOutMachines.map((data:any) => data.MachineName));
  
	// Return a predicate function for use in .filter()
	return (output:any) => {
	  const machineName = output[`${cubeName}.MachineName`];
	  return !inputSet.has(machineName);
	};
}
