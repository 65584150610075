import { IM_PLANT, IM_PLANT_ERROR, IM_PLANT_SUCCESS } from "../Types/plantIM.constant";

const INITIAL_STATE = {
    loading: false,
    selcetedPlant: {
        "_id": "6474ad7b58a357fb4d655a22",
        "plant_name": "Rialto Plant",
        "plant_code": "RIA",
        "plant_latitude": 34.12567459575512,
        "plant_longitude": -117.41652529999999,
        "plant_timezone": "America/Chicago",
        "location_id": "6474acd658a357fb4d655a1f",
        "organization_id": "6082b4d50e4acb04e8b5df29",
        "country_id": "6082b44f0e4acb04e8b5df28",
        "plant_next_level_assets": [],
        "mapped_reports": [],
        "plant_actioner": [
            {
                "region": "WEST",
                "resource_code": "RIA1",
                "type": "Water",
                "role": [
                    {
                        "_id": "65e6539cf4df3d1f7ebc04c5",
                        "actioner_role": "FG Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653a5f4df3d1f7ebc04c6",
                        "actioner_role": "Core FG Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653b2f4df3d1f7ebc04c7",
                        "actioner_role": "Core Sr. Analyst",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653bdf4df3d1f7ebc04c8",
                        "actioner_role": "Core Master Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653c7f4df3d1f7ebc04c9",
                        "actioner_role": "Brand Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653d0f4df3d1f7ebc04ca",
                        "actioner_role": "SNP Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653daf4df3d1f7ebc04cb",
                        "actioner_role": "NPI Analyst",
                        "actioner_list": []
                    }
                ]
            },
            {
                "region": "WEST",
                "resource_code": "RIA2",
                "type": "Water",
                "role": [
                    {
                        "_id": "65e6539cf4df3d1f7ebc04c5",
                        "actioner_role": "FG Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653a5f4df3d1f7ebc04c6",
                        "actioner_role": "Core FG Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653b2f4df3d1f7ebc04c7",
                        "actioner_role": "Core Sr. Analyst",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653bdf4df3d1f7ebc04c8",
                        "actioner_role": "Core Master Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653c7f4df3d1f7ebc04c9",
                        "actioner_role": "Brand Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653d0f4df3d1f7ebc04ca",
                        "actioner_role": "SNP Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653daf4df3d1f7ebc04cb",
                        "actioner_role": "NPI Analyst",
                        "actioner_list": []
                    }
                ]
            },
            {
                "region": "WEST",
                "resource_code": "RIA3",
                "type": "Water",
                "role": [
                    {
                        "_id": "65e6539cf4df3d1f7ebc04c5",
                        "actioner_role": "FG Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653a5f4df3d1f7ebc04c6",
                        "actioner_role": "Core FG Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653b2f4df3d1f7ebc04c7",
                        "actioner_role": "Core Sr. Analyst",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653bdf4df3d1f7ebc04c8",
                        "actioner_role": "Core Master Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653c7f4df3d1f7ebc04c9",
                        "actioner_role": "Brand Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653d0f4df3d1f7ebc04ca",
                        "actioner_role": "SNP Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653daf4df3d1f7ebc04cb",
                        "actioner_role": "NPI Analyst",
                        "actioner_list": []
                    }
                ]
            },
            {
                "region": "WEST",
                "resource_code": "RIA4",
                "type": "Beverage",
                "role": [
                    {
                        "_id": "65e6539cf4df3d1f7ebc04c5",
                        "actioner_role": "FG Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653a5f4df3d1f7ebc04c6",
                        "actioner_role": "Core FG Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653b2f4df3d1f7ebc04c7",
                        "actioner_role": "Core Sr. Analyst",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653bdf4df3d1f7ebc04c8",
                        "actioner_role": "Core Master Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653c7f4df3d1f7ebc04c9",
                        "actioner_role": "Brand Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653d0f4df3d1f7ebc04ca",
                        "actioner_role": "SNP Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653daf4df3d1f7ebc04cb",
                        "actioner_role": "NPI Analyst",
                        "actioner_list": []
                    },
                    {
                        "actioner_role": "Brand Planner",
                        "actioner_list": []
                    },
                    {
                        "actioner_role": "Plant Director",
                        "actioner_list": []
                    }
                ]
            },
            {
                "region": "WEST",
                "resource_code": "RIA5",
                "type": "Beverage",
                "role": [
                    {
                        "_id": "65e6539cf4df3d1f7ebc04c5",
                        "actioner_role": "FG Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653a5f4df3d1f7ebc04c6",
                        "actioner_role": "Core FG Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653b2f4df3d1f7ebc04c7",
                        "actioner_role": "Core Sr. Analyst",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653bdf4df3d1f7ebc04c8",
                        "actioner_role": "Core Master Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653c7f4df3d1f7ebc04c9",
                        "actioner_role": "Brand Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653d0f4df3d1f7ebc04ca",
                        "actioner_role": "SNP Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653daf4df3d1f7ebc04cb",
                        "actioner_role": "NPI Analyst",
                        "actioner_list": []
                    },
                    {
                        "actioner_role": "Brand Planner",
                        "actioner_list": []
                    },
                    {
                        "actioner_role": "Plant Director",
                        "actioner_list": []
                    }
                ]
            },
            {
                "region": "WEST",
                "resource_code": "RIA6",
                "type": "Beverage",
                "role": [
                    {
                        "_id": "65e6539cf4df3d1f7ebc04c5",
                        "actioner_role": "FG Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653a5f4df3d1f7ebc04c6",
                        "actioner_role": "Core FG Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653b2f4df3d1f7ebc04c7",
                        "actioner_role": "Core Sr. Analyst",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653bdf4df3d1f7ebc04c8",
                        "actioner_role": "Core Master Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653c7f4df3d1f7ebc04c9",
                        "actioner_role": "Brand Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653d0f4df3d1f7ebc04ca",
                        "actioner_role": "SNP Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653daf4df3d1f7ebc04cb",
                        "actioner_role": "NPI Analyst",
                        "actioner_list": []
                    },
                    {
                        "actioner_role": "Brand Planner",
                        "actioner_list": []
                    },
                    {
                        "actioner_role": "Plant Director",
                        "actioner_list": []
                    }
                ]
            },
            {
                "region": "WEST",
                "resource_code": "RIA7",
                "type": "Beverage",
                "role": [
                    {
                        "_id": "65e6539cf4df3d1f7ebc04c5",
                        "actioner_role": "FG Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653a5f4df3d1f7ebc04c6",
                        "actioner_role": "Core FG Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653b2f4df3d1f7ebc04c7",
                        "actioner_role": "Core Sr. Analyst",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653bdf4df3d1f7ebc04c8",
                        "actioner_role": "Core Master Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653c7f4df3d1f7ebc04c9",
                        "actioner_role": "Brand Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653d0f4df3d1f7ebc04ca",
                        "actioner_role": "SNP Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653daf4df3d1f7ebc04cb",
                        "actioner_role": "NPI Analyst",
                        "actioner_list": []
                    },
                    {
                        "actioner_role": "Brand Planner",
                        "actioner_list": []
                    },
                    {
                        "actioner_role": "Plant Director",
                        "actioner_list": []
                    }
                ]
            },
            {
                "region": "WEST",
                "resource_code": "RIA8",
                "type": "Water",
                "role": [
                    {
                        "_id": "65e6539cf4df3d1f7ebc04c5",
                        "actioner_role": "FG Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653a5f4df3d1f7ebc04c6",
                        "actioner_role": "Core FG Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653b2f4df3d1f7ebc04c7",
                        "actioner_role": "Core Sr. Analyst",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653bdf4df3d1f7ebc04c8",
                        "actioner_role": "Core Master Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653c7f4df3d1f7ebc04c9",
                        "actioner_role": "Brand Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653d0f4df3d1f7ebc04ca",
                        "actioner_role": "SNP Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653daf4df3d1f7ebc04cb",
                        "actioner_role": "NPI Analyst",
                        "actioner_list": []
                    }
                ]
            },
            {
                "region": "WEST",
                "resource_code": "RIA9",
                "type": "Water",
                "role": [
                    {
                        "_id": "65e6539cf4df3d1f7ebc04c5",
                        "actioner_role": "FG Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653a5f4df3d1f7ebc04c6",
                        "actioner_role": "Core FG Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653b2f4df3d1f7ebc04c7",
                        "actioner_role": "Core Sr. Analyst",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653bdf4df3d1f7ebc04c8",
                        "actioner_role": "Core Master Planner",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653c7f4df3d1f7ebc04c9",
                        "actioner_role": "Brand Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653d0f4df3d1f7ebc04ca",
                        "actioner_role": "SNP Manager",
                        "actioner_list": []
                    },
                    {
                        "_id": "65e653daf4df3d1f7ebc04cb",
                        "actioner_role": "NPI Analyst",
                        "actioner_list": []
                    }
                ]
            }
        ],
        "plant_maint_org": "R24"
    },
    error: null,
};

export const plantIMReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case IM_PLANT:
            return { ...state, loading: true, selcetedPlant: null };
        case IM_PLANT_SUCCESS:
            return {
                ...state,
                loading: false,
                selcetedPlant: action.payload,
            };
        case IM_PLANT_ERROR:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};
