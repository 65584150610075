// import { Injectable } from '@angular/core';
import * as FileSaver from "file-saver";
import * as ExcelJS from "exceljs";

export class ExcelService {
  private EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  private EXCEL_EXTENSION = ".xlsx";

  public async exportAsExcelFile(
    worksheetData: any,
    excelFileName: string,
    sheetNames: any
  ): Promise<void> {
    const workbook = new ExcelJS.Workbook();

    //Setting up Each worksheet
    const workSheets = sheetNames.map((element: any) => {
      return workbook.addWorksheet(element);
    });
    // Seeting up Each Headers Row
    const headers = worksheetData.map((data: any) => {
      const ele = data;
      if (ele !== undefined && ele.length > 0) {
        const eleKeys = Object.keys(ele[0]);
        return eleKeys.map((key: any) => {
          return { header: key, key: key };
        });
      }
      return [];
    });

    workSheets.forEach((sheet: any, i: number) => {
      sheet.columns = headers[i];
      sheet.getRow(1).font = { bold: true };
    });
    //Adding Rows for Each Worksheets
    workSheets.forEach((sheet: any, i: number) => {
      if (worksheetData[i]) {
        worksheetData[i].forEach((data: any) => {
          sheet.addRow(data);
        });
      }

      // Setting column width equal to content
      sheet.columns.forEach(function (column: any, i: number) {
        var maxLength = 0;
        column["eachCell"]({ includeEmpty: true }, function (cell: any) {
          var columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength + 2;
      });
    });

    // Saving
    try {
      const excelBuffer = await workbook.xlsx.writeBuffer();
      this.saveAsExcelFile(excelBuffer, excelFileName);
    } catch (err) {
      console.log(err);
    }
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + this.EXCEL_EXTENSION);
  }
}
