import { makeStyles } from "@mui/styles";
import { Theme } from "@material-ui/core";
export const dashboardStyles = makeStyles((theme: Theme) => ({
  // Table css
  row: {
    cursor: "pointer",
  },
  hover: {
    "&:hover": {
      backgroundColor: "#293368 !important",
    },
  },
  iconColor: {
    "&.MuiIconButton-root": {
      color: "#1976d2",
    },
  },
  iconFilterButtonBg: {
    "&.MuiIconButton-root": {
      color: "#1976d2",
      backgroundColor: "#E5E7F1",
    },
  },
  iconFilterButtonNonBg: {
    "&.MuiIconButton-root": {
      color: "#1976d2",
      backgroundColor: "transparent",
    },
  },
  tableRow1: {
    "&.MuiTableRow-root": {
      backgroundColor: "#242C59",

      "&:hover": {
        backgroundColor: "#242C5990",
      },
    },
  },
  tableRow2: {
    "&.MuiTableRow-root": {
      backgroundColor: "#2D376D",

      "&:hover": {
        backgroundColor: "#2D376D70",
      },
    },
  },
  tableBodyTypography: {
    "&.MuiTypography-root": {
      fontWeight: 500,
      fontSize: "14px",
      color: "#FFF",
      textAlign: "center",
    },
  },

  tableBodyTypographyPriroty: {
    "&.MuiTableCell-root": {
      textAlign: "-webkit-center",
    },
  },

  High: {
    "&.MuiTypography-root": {
      color: "#E06060",
    },
  },

  Low: {
    "&.MuiTypography-root": {
      color: "#FFF",
    },
  },

  Medium: {
    "&.MuiTypography-root": {
      color: "#FF0",
    },
  },

  waterStatus: {
    minWidth: "98px",
    padding: "1.5px 10px",
    fontSize: "14px",
    fontWeight: 500,
    maxWidth: "fit-content",
    borderRadius: "20px",
    textAlign: "center",
  },

  Closed: {
    background: "#1DAC56",
  },
  TBD: {
    background: "#FF8A65",
  },
  confirmed: {
    background: "transparenttransparent",
    border: "0.5px solid #FFF",
  },
  InProgress: {
    background: "#FF0",
    color: "#232A58 !important",
  },
  Open: {
    background: "#F00",
  },

  tableHeadTypography: {
    "&.MuiTableCell-root": {
      fontWeight: 400,
      textAlign: "center",
      fontSize: "16px",
      lineHeight: "31px",
      color: "#E6E1E5",
      fontFamily: "Poppins",
      textTransform: "capitalize",

      //   display: "inline-flex",
      //   justifyContent: "space-between",
      //   width: "100%",
      position: "relative",
      "& .MuiIconButton-root": {
        position: "absolute",
        right: " 20px",
        top: "20px",
        color: "#fff",
        textTranform: "capitalize",
      },
    },
  },
  tableBodyCell: {
    "&.MuiTableCell-root": {
      textAlign: "center",
    },
  },
  // Header css
  criticalControlSec: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      border: "1px solid #8476ef",
      background: "#293368",
      padding: "12px !important",
      "& .MuiAutocomplete-input": {
        padding: "0 !important",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "14px",
      "&::placeholder": {
        color: "#FFF",
      },
    },
    "& .css-19kzrtu": {
      padding: 0,
    },
  },
  fliterMenus: {
    display: "flex",
    gap: "12px",
    justifyContent: "flex-end",
    "& .manualsBtn": {
      "& .MuiButtonBase-root": {
        borderRadius: "6px",
        border: "0.895px solid #8476EF",
        background: "#293368",
        display: "flex",
        height: "46px",
        padding: "10px 20px",
        alignItems: "center",
        gap: "20px",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        maxWidth: "max-content",
        width: "100%",
        "& .MuiButton-iconSizeMedium": {
          marginRight: 0,
        },
      },
    },
    "& .MuiTabs-scroller": {
      overflow: "visible !important",
      "& .MuiButtonBase-root": {
        margin: 0,
        borderColor: "#ABA7AD",
        background: "#232A58",
        "&:first-child": {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
        "&:last-child": {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      },
      "& .Mui-selected": {
        borderColor: "#8476EF",
        background:
          "linear-gradient(0deg, rgba(132, 118, 239, 0.30) 0%, rgba(132, 118, 239, 0.30) 100%), #232A58",
      },
    },
  },
  createBtn: {
    "& .MuiButtonBase-root": {
      borderRadius: "10px",
      background: "#8476EF",
      padding: "9px 35px",
      width: "auto",
      "@media only screen and (max-width:1280px) ": {
        padding: "9px 29px",
      },
    },
    "& .createBtn": {
      paddingBottom: "36px",
    },
  },
  datePickerBox: {
    "&.rs-picker-default ": {
      "& .rs-picker-toggle.rs-btn": {
        background: "#293368 !important",
        borderRadius: "10px",
        border: "1px solid #8476EF",
        padding: "14px 32px",
        fontWeight: 500,
      },
      "& .rs-picker-toggle-caret": {
        display: "none",
      },
    },
    "& .rs-picker-toggle-textbox": {
      background: "#293368",
      color: "#fff",
      fontSize: "13px",
    },
    "& .rs-stack": {
      "& .rs-stack-item": {
        "& .rs-picker-toggle-clean": {
          top: "14px !important",
          // background: "blue",
        },
      },
      "& .rs-picker-toggle-textbox": {
        padding: "14px 32px",
        color: "#fff",
      },
    },
    "& .rs-picker-toggle-value, .rs-picker-toggle-placeholder": {
      color: "#fff !important",
    },
  },
  multiDropdown: {
    '& .MuiInputBase-input': {
      fontSize: theme.typography.body1.fontSize,
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.h6.fontSize, 
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: theme.typography.h4.fontSize,
      }
    },
    "& .select__single-value": {
      color: "#fff !important",
      padding: "13px",
    },
    "& .select__input": {
      color: "white !important",
    },
    minWidth: "150px !important",
    width: "100%",
    "& .select__control": {
      background: "#293368",
      padding: "11.5px 16px",
      "& .select__multi-value": {
        background: "#8476EF",
        padding: "6px 10px",
        "& .select__multi-value__label": {
          color: "#FFF",
          fontSize: "14px",
        },
        "& .select__multi-value__remove": {
          "&:hover": {
            backgroundColor: "transparent",
            color: "#FFF",
          },
        },
      },
      "& .select__indicator-separator": {
        display: "none",
      },
    },
    "& .select__menu ": {
      "& .select__menu-list": {
        padding: 0,
        "& .select__option ": {
          background: "#293368",
        },
      },
    },

    "& .MuiFormLabel-root ": {
      top: "-25px",
      background: "#293368",
      padding: "2px 10px",
      // "&:after": {
      //   content: "",
      //   background: "red",
      //   width: "100%",
      //   height: "10px",
      //   display: "block",
      // },
    },
  },
  MoreVertiIcon: {
    "& .MuiIconButton-root": {
      border: "1px solid #8476EF",
      color: "#fff",
      height: "46px",
    },
    "& .MuiButtonBase-root": {
      borderRadius: "10px",
      background: "transparent",
      padding: "9px 10px",
    },
  },
  bookmarkDialog: {
    "& .MuiDialogTitle-root": {
      fontSize: "20px",
      fontWeight: 500,
      marginBottom: "0px",
      padding: "0px",
    },
    "& .MuiTypography-body1": {
      color: "#FFF",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "14px",
    },
    "& .contentWrapper": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "15px !important",
    },

    "& .MuiAutocomplete-clearIndicator": {
      background: "transparent",
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
    },
    "& .btnFoot": {
      display: "flex",
      gap: "19px",
      width: "100%",
      justifyContent: "center",
      "& .MuiButtonBase-root": {
        borderRadius: "6px",
        border: "0.895px solid #8476EF",
        background: "#293368",
        display: "flex",
        height: "46px",
        padding: "9px 41px",
        alignItems: "center",
        gap: "20px",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        maxWidth: "max-content",
        width: "100%",
        "& .MuiButton-iconSizeMedium": {
          marginRight: 0,
        },
      },
      "& .saveBtn": {
        background: "#8476EF",
      },
    },
  },
  tableGrid1:{
    borderRadius:"10px",
    marginTop: "24px !important",
    width:"97%",
    marginLeft: "24px !important",
    padding: "25px 25px",
    backgroundColor:"rgb(45, 55, 109)",
    "& .MuiCard-root":{
      padding: "20px 0px 0px 0px !important"
    },
    "& .MuiBox-root":{
      padding:"0px !important"
    }
  },
  tableGrid: {
    marginTop: "24px",
    marginBottom: "24px",
  },
  autoCompletetSelect: {
    display: "contents",
    "& .MuiInputBase-root": {
      border: "none",
    },
    "& .MuiAutocomplete-input": {
      color: "#fff",
      "&::placeholder": {
        color: "#fff",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #ABA7AD",
      borderRadius: "5px",
      background: "#293368",
    },
  },
  backButtonAndAutoComplete: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
  },
  tagsConatiner: {
    marginTop: "10px",
    marginLeft: "10px",
    display: "flex",
    flexDirection: "column",
    color: "black",
  },
  tabsControl: {
    "& .MuiTabs-flexContainer": {
      width: "fit-content",
      maxWidth: "100%",
      "& .MuiButtonBase-root": {
        borderRadius: "0",
        border: "1px solid #ABA7AD",
        margin: "0",

        "&:first-child": {
          borderTopLeftRadius: "59px",
          borderBottomLeftRadius: "59px",
          borderRight: "0",
        },
        "&:last-child": {
          borderTopRightRadius: "59px",
          borderBottomRightRadius: "59px",
          borderLeft: "0",
        },
        "&.Mui-selected": {
          background: "rgba(132, 118, 239, 0.30)",
          borderColor: "#8476EF",
        },
      },
    },
    "& .MuiTabs-indicator": {
      background: "transparent",
    },
  },
  searchBarHead: {
    gap: "16px",
  },
  autoDropdown: {
    "& .MuiInputBase-root": {
      display: "flex",
      height: "47px",
      "&:before": {
        border: "none",
      },
      "&::placeholder": {
        color: "#fff !important",
      },
    },
    "& .MuiInputBase-input": {
      width: "100% !important",
      fontSize: theme.typography.body1.fontSize,
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.h6.fontSize, 
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: theme.typography.h4.fontSize,
      }
    },
  },
  statusDatePiker: {
    width: "100%",
    "& > div": {
      width: "100%",
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
  },
  templateDropdown: {
    minWidth: "300px",
  },
  createTicketFields: {
    width: "100%",
    gap: "16px",
    display: "flex",
    flexDirection: "column",
    paddingTop: "36px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      border: "1px solid #D6D1FA",
    },
  },
  searchFilter: {
    "& .MuiFormControl-root": {
      maxWidth: "400px",
    },
  },
  searchBox: {
    maxWidth: "250px",
    "@media only screen and (max-width:1440px) ": {
      maxWidth: "200px",
    },
    "& .MuiInputBase-root": {
      height: "47px",
      "&:before": {
        border: "none",
      },
      "&::placeholder": {
        color: "#fff !important",
      },
    },
  },
  tickeDialog: {
    "& .MuiTypography-h6": {
      fontSize: "20px",
      fontWeight: 400,
      textAlign: "center",
      padding: "0",
      "& .MuiTypography-body1": {
        fontSize: "12px",
        fontWeight: 300,
      },
    },
    "& .MuiList-root": {
      padding: "0",
      "& .MuiListItem-root": {
        padding: "0 0 0 4px",
        fontSize: "12px",
        fontWeight: 300,
      },
    },
    "& .btnFoot": {
      display: "flex",
      gap: "12px",
      width: "100%",
      marginTop: "19px",
      "& .MuiButtonBase-root": {
        borderRadius: "6px",
        border: "0.895px solid #8476EF",
        background: "#293368",
        display: "flex",
        height: "46px",
        padding: "10px 20px",
        alignItems: "center",
        gap: "20px",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        width: "100%",
        "& .MuiButton-iconSizeMedium": {
          marginRight: 0,
        },
      },
      "& .saveBtn": {
        background: "#8476EF",
      },
      "& .deleteBtn": {
        background: "#E06060",
        border: "none",
        fontWeight: 500,
      },
      "& .doneBtn": {
        "&.MuiButtonBase-root": {
          maxWidth: "146px",
          margin: "0 auto",
          background: "#8476EF",
        },
      },
    },
  },
  dialogTitleContainer: {
    width: "649px",
    padding: "28px 20px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  confirmDialogWrapper: {
    width: "363px",
    padding: "24px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    background: "#232A58",
  },
  confirmDialogTitleAndIcon: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    gap: "19px",
  },
  popOverIconMargin: {
    marginRight: "5px",
  },
  popOverMenuList: {
    background: "#343D70",
    padding: "0px",
  },
}));
