import React, { ChangeEvent, ReactElement, useState } from "react";
import {
  actionItem,
  dataTableProperties,
  tableColumn,
} from "./DataTableInterface";
import CircularProgress from "@material-ui/core/CircularProgress";
import FilterAltTwoToneIcon from "@mui/icons-material/FilterAltTwoTone";
import {
  Divider,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  Button,
  Link,
} from "@material-ui/core";
import FilterListTwoToneIcon from "@mui/icons-material/FilterListTwoTone";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ReplayIcon from "@mui/icons-material/Replay";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Grid, Skeleton } from "@mui/material";
import { dataTableStyles } from "./Styles";
import {
  alternativeStyle,
  defaultExportCsvName,
  defaultPage,
  defaultRowsPerPage,
  defaultRowsPerPageOptions,
  defaultTableLengthCount,
} from "./Constants";
import TextField from "@material-ui/core/TextField";
import Popover from "@mui/material/Popover";
import CardContent from "@mui/material/CardContent";
import * as XLSX from "xlsx";
import saveAs from "file-saver";

const DataTable = (props: dataTableProperties): ReactElement => {
  const classes = dataTableStyles();
  const [sortAscending, setSortAscending] = React.useState<boolean>(true);
  const [sortedColumn, setSortedColumn] = React.useState<string>("");

  const [filterParams, setFilterParams] = React.useState<any>({
    sort: props?.defaultSort || {},
  });
  const [searchColumns, setSearchColumns] = React.useState<any>([]);
  const [currentSearchColumn, setCurentSearchColumn] = React.useState<{
    anchorElement: null | HTMLElement;
    column: string;
    columnAttribue: string;
    dbColumnName: string;
  }>({
    anchorElement: null,
    column: "",
    columnAttribue: "",
    dbColumnName: "",
  });

  const openFilter = Boolean(currentSearchColumn.anchorElement);
  const [searchColumnsWithValues, setSearchColumnWithValues] = useState({});
  const [debouncedSearchQuery, setDebouncedSearchQuery] = React.useState(0);

  React.useEffect(() => {
    setFilterParams({});
    setSortedColumn("");
    setSortAscending(true);
  }, [props?.tableDependency]);

  React.useEffect(() => {
    // Use a timer to debounce the search query updates
    const debounceTimer = setTimeout(() => {
      const searchText = Object.values(searchColumnsWithValues);
      setDebouncedSearchQuery(searchText.join("").length);
    }, 500);

    // Cleanup the timer on every searchQuery change
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchColumnsWithValues]);

  React.useEffect(() => {
    if (typeof props.handleSearch === "function") {
      const handleFiltes = {
        ...filterParams,
        ...searchColumnsWithValues,
      };
      props.handleSearch(handleFiltes);
    }
  }, [debouncedSearchQuery]);

  /**
   * Function to apply pagination to data table.
   * @param data
   * @returns
   */
  // const applyPagination = (data: any): any => {
  //   if (props?.pagination) {
  //     const limit = props?.paginationOptions?.rowsPerPage || defaultRowsPerPage;
  //     const page = props?.paginationOptions?.page || defaultPage;
  //     // Slice start value is always zero, when api call is happen
  //     return data.slice(page * limit, page * limit + limit);
  //   } else {
  //     return data;
  //   }
  // };

  /**
   * Function to handle sort click actions either ascending | descending order
   * @param header
   */
  const handleSortClick = (header: tableColumn) => {
    if (typeof props.handleSort === "function") {
      setSortedColumn(header?.sortPriorityKey || header.key);
      const isSortAsceding =
        header?.sortPriorityKey === sortedColumn || header.key === sortedColumn
          ? !sortAscending
          : true;
      setSortAscending(isSortAsceding);

      let sortValue = isSortAsceding ? 1 : -1;

      filterParams["sort"] = {
        [header?.sortPriorityKey || header.key]: sortValue,
      };

      if (typeof props?.handleSort === "function") {
        props.handleSort({ ...filterParams, ...searchColumnsWithValues });
      }
    }
  };

  /**
   * Function to handle Table Row Background
   * @param tableData
   */
  const setHandleTableRowBackground = (tableData: any, index: number) => {
    if (typeof props.handleTableRowBackground === "function") {
      return props.handleTableRowBackground(tableData, index);
    }
  };

  /**
   * Function to handle search of column and setting up value
   * @param columnName
   * @param value
   */
  const setSearchValue = (columnName: string, value: string) => {
    let tempFilter = [...searchColumns];
    if (
      searchColumns.find((ele: any) => {
        return ele.column === columnName;
      }) === undefined
    ) {
      setSearchColumns([
        {
          column: columnName,
          display: "",
          value: value,
          attributeName: currentSearchColumn.columnAttribue,
        },
        ...searchColumns,
      ]);
    } else {
      let item = {
        ...searchColumns.find((ele: any) => {
          return ele.column === columnName;
        }),
      };
      item.value = value;

      tempFilter[
        tempFilter.findIndex((ele: any) => {
          return ele.column === columnName;
        })
      ] = item;

      setSearchColumns(tempFilter);
      // handlePageChange(null, 0);
    }
  };

  const s2ab = (s: any) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  /**
   * Function to export table data into csv
   */
  const handleExportAction = (): void => {
    const data = props?.data;

    let csvData: any = [];

    props.columns.forEach((element: any) => {
      const rowData: any = [];
      data.forEach((row: any) => {
        rowData.push(row[element.key]);
      });
      csvData.push({ tag: element.label, data: rowData });
    });
    const wb = XLSX.utils.book_new();
    const rowPerSheet = 1000000;
    const maxLength = Math.max(...csvData.map((item: any) => item.data.length));
    const numberOfSheets = Math.ceil(maxLength / rowPerSheet);
    const columnName: any = csvData.map((item: any) => item.tag);

    for (let sheetIndex = 0; sheetIndex < numberOfSheets; sheetIndex++) {
      const wsData = [columnName];

      for (let i = 0; i < rowPerSheet; i++) {
        let dataIndex = sheetIndex * rowPerSheet + i;
        if (dataIndex < maxLength) {
          const row = csvData.map((item: any) => item.data[dataIndex]);
          wsData.push(row);
        }
      }

      const ws: any = XLSX.utils.aoa_to_sheet(wsData);

      const boldCell = { font: { bold: true } };
      let columnwidth = [];

      for (let j = 0; j < columnName.length; j++) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: j });
        ws[cellAddress] = { v: columnName[j], s: boldCell };

        const columnNameLength = columnName[j].length;
        const width = columnNameLength > 10 ? columnNameLength * 2 : 20;
        columnwidth.push({ wch: width });
      }
      ws["!cols"] = columnwidth;
      XLSX.utils.book_append_sheet(wb, ws, `Sheet${sheetIndex + 1}`);
    }
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-sream",
    });
    const fileName = props.exportCsvOptions?.name || defaultExportCsvName;
    saveAs(blob, fileName);
  };

  /**
   * function to handle reload data table
   */
  const handleReloadAction = (): void => {
    if (typeof props.handleReload === "function") {
      props.handleReload();
    }
  };

  /**
   * Function to handle row ations, Ex: Edit, delete, alerts ... etc
   * @param item
   * @param row
   */
  const handleRowItemAction = (item: actionItem, row: any): void => {
    if (typeof item?.handleAction === "function") {
      item.handleAction(row);
    }
  };

  /**
   * Function to change data table limit changes
   * @param event
   */
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (typeof props?.paginationOptions?.onRowsPerPageChange === "function") {
      const sortObject = {
        sort: {
          [sortedColumn]: sortAscending ? 1 : -1,
        },
      };
      props.paginationOptions.onRowsPerPageChange(event, sortObject);
    }
  };

  /**
   * Function to change data table page change
   * @param event
   * @param newPage
   */
  const handlePageChange = (event: any, newPage: number): void => {
    if (typeof props?.paginationOptions?.onPageChange === "function") {
      const sortObject = {
        sort: {
          [sortedColumn]: sortAscending ? 1 : -1,
        },
      };
      props.paginationOptions.onPageChange(event, newPage, sortObject);
    }
  };

  /**
   * Function to set up current column search value and column attribue
   * @param anchor
   * @param columnName
   * @param attribute
   */
  const handleColumnSearching = (
    anchor: any,
    columnName: any,
    attribute: any,
    dbColumnName: any
  ) => {
    setCurentSearchColumn({
      anchorElement: anchor,
      column: columnName,
      columnAttribue: attribute,
      dbColumnName: dbColumnName,
    });
  };

  /**
   * Function to return ReactElement of row actions
   * @param row
   * @returns
   */
  const renderRowActions = (row: any): ReactElement => {
    return (
      <TableCell>
        {props.actionItems?.map((item: actionItem) => {
          return (
            <img
              onClick={() => {
                handleRowItemAction(item, row);
              }}
              src={item?.icon}
              className={classes.actionIcon}
            />
          );
        })}
      </TableCell>
    );
  };

  /**
   * Function to handle link actios of header
   * @param row
   */
  const handleLinkAction = (row: any, column: tableColumn): void => {
    if (typeof column.handleLink === "function") {
      column.handleLink(row);
    }
  };
  const handleRowAction = (row: any): void => {
    if (typeof props.handleRowClick === "function") {
      props.handleRowClick(row);
    }
  };

  return (
    <>
      {/*Section to handle reload and export csv actions*/}
      {(props?.reload || props.exportCsv) && (
        <Grid container justifyContent="right" alignItems="center" spacing={2}>
          <Grid item xs={2} display="flex">
            {props?.exportCsv && (
              <Button
                style={{ marginRight: "5px" }}
                variant="contained"
                onClick={() => {
                  handleExportAction();
                }}
                endIcon={<FileDownloadIcon />}
              >
                Export Csv
              </Button>
            )}
            {props?.reload && (
              <Button
                size="large"
                variant="contained"
                onClick={() => handleReloadAction()}
                endIcon={<ReplayIcon />}
              >
                Reload
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      <Card className={classes.cardBg}>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {props.columns?.map((header: tableColumn, i: number) => (
                  <TableCell
                    className={classes.tableHeadTypography}
                    style={{ ...(header?.headerStyle as React.CSSProperties) }}
                  >
                    {/* handle column mapping */}
                    {header.label}
                    {props?.sorting && (
                      <IconButton
                        className={classes.iconColor}
                        color="inherit"
                        size="small"
                        onClick={() => {
                          handleSortClick(header);
                        }}
                      >
                        <FilterListTwoToneIcon fontSize="small" />
                        {sortedColumn === header?.sortPriorityKey ||
                        sortedColumn === header.key ? (
                          sortAscending ? (
                            <ArrowDropDownIcon fontSize="small" />
                          ) : (
                            <ArrowDropUpIcon fontSize="small" />
                          )
                        ) : (
                          ""
                        )}
                      </IconButton>
                    )}

                    {props?.searching && (
                      <IconButton
                        data-testid="filter-button"
                        className={
                          searchColumns
                            ?.map((ele: any) => {
                              return ele.attributeName;
                            })
                            .includes(header.key) &&
                          searchColumns?.find((ele: any) => {
                            return ele.attributeName === header.key;
                          })?.value !== ""
                            ? classes.iconFilterButtonBg
                            : classes.iconFilterButtonNonBg
                        }
                        color="inherit"
                        size="small"
                        onClick={(event) => {
                          handleColumnSearching(
                            event.currentTarget,
                            header.label,
                            header.key,
                            header.dbColumnName
                          );
                        }}
                      >
                        <FilterAltTwoToneIcon />
                      </IconButton>
                    )}
                  </TableCell>
                ))}
                {props.actions && (
                  <TableCell className={classes.tableHeadTypography}>
                    actions
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                // Handling loader
                props?.loading ? (
                  Array(5)
										.fill(1)
										.map(() => (
											<TableRow>
												<TableCell colSpan={props.columns?.length}>
													<Skeleton variant='rectangular' />
												</TableCell>
											</TableRow>
										))
								) : props.data?.length > 0 ? (
									props.data?.map((row: any, index: number) => (
										<TableRow
											onClick={() => {
												handleRowAction(row)
											}}
											hover
											key={row?.id}
											style={
												props?.tableRowBackground
													? setHandleTableRowBackground(row, index)
													: index % 2 === 0
														? alternativeStyle(index, props?.evenRowColor)
														: alternativeStyle(index, props?.oddRowColor)
											}
											className={classes.hoverCursor}
										>
											{props.columns?.map((column: tableColumn) => {
												return column.link ? (
													<TableCell>
														{typeof column.renderCell === 'function' ? (
															column.renderCell(row)
														) : (
															<Typography
																variant='body1'
																gutterBottom
																component={Link}
																sx={{ textDecorationColor: 'white' }}
																onClick={() => {
																	handleLinkAction(row, column)
																}}
																className={`${classes.tablePointer} ${classes.tableBodyTypography}`}
																style={{
																	...(column?.cellStyle as React.CSSProperties)
																}}
															>
																{row[column.key]}
															</Typography>
														)}
													</TableCell>
												) : (
													<TableCell
														style={{
															...(column?.cellStyle as React.CSSProperties)
														}}
													>
														{typeof column.renderCell === 'function' ? (
															column.renderCell(row)
														) : (
															<Typography
																variant='body1'
																className={classes.tableBodyTypography}
																gutterBottom
															>
																{row[column.key]}
															</Typography>
														)}
													</TableCell>
												)
											})}

											{/*  Rendering row actions */}
											{props.actions && renderRowActions(row)}
										</TableRow>
									))
								) : (
									<Typography
										variant='h3'
										sx={{
											margin: '20px 0 0px 10px',
											width: 'max-content'
										}}
									>
										{props.defaultEmptyMessage}
									</Typography>
								)
							}
						</TableBody>
					</Table>
				</TableContainer>
				{props.pagination && (
					<Box p={2} className="pagination">
						<TablePagination
							component='div'
							count={props?.paginationOptions?.count || defaultTableLengthCount} // count not of data.... it all length
							onPageChange={handlePageChange}
							onRowsPerPageChange={handleLimitChange}
							page={props?.paginationOptions?.page || defaultPage}
							rowsPerPage={
								props?.paginationOptions?.rowsPerPage || defaultRowsPerPage
							}
							rowsPerPageOptions={
								props?.paginationOptions?.rowsPerPageOptions ||
								defaultRowsPerPageOptions
							}
						/>
					</Box>
				)}
			</Card>

      {/* ReactElement snippet to take column search textinput */}
      <Popover
        anchorEl={currentSearchColumn.anchorElement}
        open={openFilter}
        onClose={() => {
          setCurentSearchColumn({
            ...currentSearchColumn,
            anchorElement: null,
          });
        }}
      >
        <Card>
          <CardContent>
            <TextField
              id="outlined-basic"
              label={currentSearchColumn.column}
              inputProps={{
                "data-testid": "filter-textfield",
                sx: { color: "white" },
              }}
              variant="outlined"
              value={
                searchColumns.find((ele: any) => {
                  return (
                    ele?.attributeName === currentSearchColumn.columnAttribue
                  );
                }) !== undefined
                  ? searchColumns.find((ele: any) => {
                      return (
                        ele.attributeName === currentSearchColumn.columnAttribue
                      );
                    }).value
                  : ""
              }
              onChange={(event) => {
                setSearchValue(currentSearchColumn.column, event.target.value);
                let currentValue = event.target.value;

                if (currentSearchColumn.columnAttribue) {
                  setSearchColumnWithValues({
                    ...searchColumnsWithValues,
                    [currentSearchColumn.dbColumnName]: currentValue,
                  });
                }
              }}
            />
          </CardContent>
        </Card>
      </Popover>
    </>
  );
};

export default React.memo(DataTable);
