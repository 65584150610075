import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function CIPHealthLevelCircularProgress(props: any) {
  return (
    <Box
      style={{
        position: "relative",
        marginRight: "1em",
        display: "inline",
        top: "0.75em",
      }}
    >
      <CircularProgress
        variant="determinate"
        value={100}
        thickness={7}
        style={{
          width: "43px",
          height: "43px",
          borderRadius: "100%",
          color:
            props.currentValue < props.lowerThreshold
              ? "rgba(214, 69, 80, 1)"
              : props.currentValue > props.upperThreshold
              ? "rgba(217, 179, 0, 1)"
              : "rgba(147, 229, 48, 1)",
        }}
      />
    </Box>
  );
}
