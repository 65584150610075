import { Box } from "@material-ui/core";
import HeaderNotifications from "./Notifications";

function HeaderActions() {
  return (
    <Box sx={{ mr: 1 }}>
      <Box sx={{ mx: 0.5 }} component="span">
        <HeaderNotifications />
      </Box>
    </Box>
  );
}

export default HeaderActions;
