import { combineReducers } from "redux";
import { usecaseListReducer } from "./Reducers/usecase.reducer";
import { locationsListReducer } from "./Reducers/locations.reducer";
import { plantsListReducer } from "./Reducers/plants.reducer";
import { assetsListReducer } from "./Reducers/assets.reducers";
import { levelsListReducer } from "./Reducers/levels.reducers";
// import { reportsListReducer } from "./Reducers/reports.reducer";
import { dashboardsListReducer } from "./Reducers/dashboards.reducers";
// import { mappedReportsListReducer } from "./Reducers/loadReports.reducers";
// import { apiReportsListReducer } from "./Reducers/reportsAPI.reducers";
import { assetTypesListReducer } from "./Reducers/assetTypes.reducer";
import { OEMListReducer } from "./Reducers/OEM.reducer";
import { modelsListReducer } from "./Reducers/models.reducer";
// import { plantsReportsListReducer } from "./Reducers/plantsReports.reducers";
import { ticketsListReducer } from "./Reducers/tickets.reducer";
import { messagesListReducer } from "./Reducers/messages.reducer";
import { chatroomListReducer } from "./Reducers/chatroom.reducer";
import { notificationsReducer } from "./Reducers/notifications.reducers";
import { devicesListReducer } from "./Reducers/devices.reducer";
import { machineListReducer } from "./Reducers/machines.reducer";
import { sensorListReducer } from "./Reducers/sensors.reducer";
import { usersListReducer } from "./Reducers/users.reducers";
import { userPermissionsListReducer } from "./Reducers/userPermissions.reducers";
import { snackBarReducer } from "./Reducers/snackBar.reducers";
import { usersImagesReducer } from "./Reducers/userImages.reducer";
import { kpisListReducer } from "./Reducers/kpis.reducers";

import { visualsListReducer } from "./Reducers/visuals.reducers";
import { aliasListReducer } from "./Reducers/alias.reducers";
import { seedsListReducer } from "./Reducers/seeds.reducers";
import { bannerReducer } from "./Reducers/banner.reducers";
import { satPlantsListReducer } from "./Reducers/satPlants.reducers";
import { satHierarchyPlantReducer } from "./Reducers/satHierarchy.reducers";
import { satKpiDataReducer } from "./Reducers/satKpiData.reducers";
import { satDetailsListReducer } from "./Reducers/satDetails.reducers";
import { SATAnnotationsListReducer } from "./Reducers/satAnnotations.reducers";
import { userAccessReducer } from "./Reducers/userAccess.reducers";
import { troubleAssetReducer } from "./Reducers/troubleshoot.reducers";
import { downtimeReducer } from "./Reducers/downtime.reducers";
import { cipWashListReducer } from "./Reducers/cipWash.reducers";
import { lgvHealthReducer } from "./Reducers/lgvHealth.reducers";
import { schedulerReducer } from "./Reducers/scheduler.reducers";
import { plantIMReducer } from "./Reducers/plantIM.reducers";

const rootReducer = combineReducers({
  usecaseList: usecaseListReducer,
  locationList: locationsListReducer,
  plantsList: plantsListReducer,
  assetsList: assetsListReducer,
  levelsList: levelsListReducer,
  // reportsList: reportsListReducer,
  dashboardsList: dashboardsListReducer,
  // mappedReportsList: mappedReportsListReducer,
  // apiReportsList: apiReportsListReducer,
  assetTypesList: assetTypesListReducer,
  OEMList: OEMListReducer,
  modelsList: modelsListReducer,
  // plantsReportsList: plantsReportsListReducer,
  ticketsList: ticketsListReducer,
  messagesList: messagesListReducer,
  chatroomList: chatroomListReducer,
  notificationsList: notificationsReducer,
  devicesList: devicesListReducer,
  machinesList: machineListReducer,
  sensorsList: sensorListReducer,
  usersList: usersListReducer,
  userPermissionsList: userPermissionsListReducer,
  snackBar: snackBarReducer,
  userListImages: usersImagesReducer,
  kpisList: kpisListReducer,
  visualsList: visualsListReducer,
  aliasList: aliasListReducer,
  seedsList: seedsListReducer,
  banner: bannerReducer,
  satPlantsList: satPlantsListReducer,
  satDetailsList: satDetailsListReducer,
  satHierarchyList: satHierarchyPlantReducer,
  satKpiDataList: satKpiDataReducer,
  satAnnotationsList: SATAnnotationsListReducer,
  userAccess: userAccessReducer,
  troubleShootAsset: troubleAssetReducer,
  downtime: downtimeReducer,
  cipWashList: cipWashListReducer,
  lgvHealth: lgvHealthReducer,
  schedulerData : schedulerReducer,
  selectedPlantIM: plantIMReducer,
});

export default rootReducer;
